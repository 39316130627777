import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

import { computed } from 'vue';

import usePoolQuery from '@/composables/queries/usePoolQuery';
import useVeBalLockInfoQuery from '@/composables/queries/useVeBalLockInfoQuery';

import useTokens from '@/composables/useTokens';
import useVeBal from '@/composables/useVeBAL';

import { FullPool } from '@/services/balancer/subgraph/types';
import useWeb3 from '@/services/web3/useWeb3';

import LockableTokens from './components/LockableTokens.vue';
import HowToLock from './components/HowToLock.vue';
import MyVeBAL from './components/MyVeBAL.vue';
import VeBalForm from './components/VeBalForm/VeBalForm.vue';

import Col3Layout from '@/components/layouts/Col3Layout.vue';
import useBreakpoints from '@/composables/useBreakpoints';

/**
 * COMPOSABLES
 */

export default _defineComponent({
  setup(__props) {

const { tokens } = useTokens();
const { isWalletReady } = useWeb3();
const { lockablePoolId } = useVeBal();
const { isDesktop, isMobile } = useBreakpoints();

/**
 * QUERIES
 */
const lockablePoolQuery = usePoolQuery(lockablePoolId.value as string);
const veBalLockInfoQuery = useVeBalLockInfoQuery();

/**
 * COMPUTED
 */
const lockablePoolLoading = computed(
  () => lockablePoolQuery.isLoading.value || lockablePoolQuery.isIdle.value
);

const veBalQueryLoading = computed(
  () => veBalLockInfoQuery.isLoading.value || veBalLockInfoQuery.isIdle.value
);

const lockablePool = computed<FullPool | undefined>(
  () => lockablePoolQuery.data.value
);

const lockablePoolTokenInfo = computed(() =>
  lockablePool.value != null ? tokens.value[lockablePool.value.address] : null
);

const veBalLockInfo = computed(() => veBalLockInfoQuery.data.value);

const isLoading = computed(() =>
  isWalletReady.value
    ? lockablePoolLoading.value || veBalQueryLoading.value
    : lockablePoolLoading.value
);

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!

  return (_openBlock(), _createBlock(Col3Layout, { offsetGutters: "" }, {
    gutterLeft: _withCtx(() => [
      (_unref(isLoading))
        ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
            key: 0,
            class: "h-36"
          }))
        : (_openBlock(), _createBlock(LockableTokens, {
            key: 1,
            lockablePool: _unref(lockablePool),
            lockablePoolTokenInfo: _unref(lockablePoolTokenInfo)
          }, null, 8, ["lockablePool", "lockablePoolTokenInfo"])),
      (_unref(isDesktop))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            (_unref(isLoading))
              ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
                  key: 0,
                  class: "h-12 mt-4"
                }))
              : (_openBlock(), _createBlock(HowToLock, {
                  key: 1,
                  lockablePool: _unref(lockablePool),
                  lockablePoolTokenInfo: _unref(lockablePoolTokenInfo)
                }, null, 8, ["lockablePool", "lockablePoolTokenInfo"]))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    gutterRight: _withCtx(() => [
      (_unref(isLoading))
        ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
            key: 0,
            class: "h-64"
          }))
        : (_openBlock(), _createBlock(MyVeBAL, {
            key: 1,
            veBalLockInfo: _unref(veBalLockInfo)
          }, null, 8, ["veBalLockInfo"])),
      (_unref(isMobile))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            (_unref(isLoading))
              ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
                  key: 0,
                  class: "h-12 mt-4"
                }))
              : (_openBlock(), _createBlock(HowToLock, {
                  key: 1,
                  lockablePool: _unref(lockablePool),
                  lockablePoolTokenInfo: _unref(lockablePoolTokenInfo)
                }, null, 8, ["lockablePool", "lockablePoolTokenInfo"]))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (_unref(isLoading))
        ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
            key: 0,
            class: "h-96"
          }))
        : (_openBlock(), _createBlock(VeBalForm, {
            key: 1,
            lockablePool: _unref(lockablePool),
            lockablePoolTokenInfo: _unref(lockablePoolTokenInfo),
            veBalLockInfo: _unref(veBalLockInfo)
          }, null, 8, ["lockablePool", "lockablePoolTokenInfo", "veBalLockInfo"]))
    ]),
    _: 1
  }))
}
}

})