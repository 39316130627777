import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-4 w-full border-b dark:border-gray-900" }
const _hoisted_2 = { class: "-mt-2 p-4" }
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = { class: "flex justify-between text-gray-500" }

import { computed } from 'vue';

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';

import { bnum } from '@/lib/utils';

import { VeBalLockInfo } from '@/services/balancer/contracts/contracts/veBAL';

import { FullPool } from '@/services/balancer/subgraph/types';
import { TokenInfo } from '@/types/TokenList';

/**
 * TYPES
 */
type Props = {
  lockablePool: FullPool;
  lockablePoolTokenInfo: TokenInfo;
  veBalLockInfo?: VeBalLockInfo;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    lockablePool: { type: null, required: true },
    lockablePoolTokenInfo: { type: null, required: true },
    veBalLockInfo: { type: null, required: false }
  } as unknown as undefined,
  setup(__props: {
  lockablePool: FullPool;
  lockablePoolTokenInfo: TokenInfo;
  veBalLockInfo?: VeBalLockInfo;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { balanceFor } = useTokens();
const { fNum2 } = useNumbers();

/**
 * COMPUTED
 */
const bptBalance = computed(() => balanceFor(props.lockablePool.address));

const fiatTotal = computed(() =>
  bnum(props.lockablePool.totalLiquidity)
    .div(props.lockablePool.totalShares)
    .times(bptBalance.value)
);

return (_ctx: any,_cache: any) => {
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    noPad: "",
    shadow: "none"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.$t('getVeBAL.lockableTokens.title')), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, _toDisplayString(__props.lockablePoolTokenInfo.symbol), 1),
          _createElementVNode("div", null, _toDisplayString(_unref(fNum2)(_unref(bptBalance), _unref(FNumFormats).token)), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, _toDisplayString(__props.lockablePoolTokenInfo.name), 1),
          _createElementVNode("div", null, _toDisplayString(_unref(fNum2)(_unref(fiatTotal), _unref(FNumFormats).fiat)), 1)
        ]),
        _createVNode(_component_BalLink, {
          tag: "router-link",
          to: { name: 'invest', params: { id: __props.lockablePool.id } },
          external: "",
          class: "mt-2 block text-sm"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('getVeBAL.lockableTokens.getMoreVeBAL', [
            __props.lockablePoolTokenInfo.symbol
          ])), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _: 1
  }))
}
}

})