import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1f59da42")
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "p-3 flex justify-between items-center" }
const _hoisted_3 = { class: "font-semibold" }
const _hoisted_4 = { class: "text-gray-400 dark:text-gray-600" }
const _hoisted_5 = { class: "grid gap-1 grid-cols-2" }
_popScopeId()

import { computed } from 'vue';

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';

import { FullPool } from '@/services/balancer/subgraph/types';

/**
 * TYPES
 */
type Props = {
  lockablePool: FullPool;
  totalLpTokens: string;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    lockablePool: { type: null, required: true },
    totalLpTokens: { type: String, required: true }
  } as unknown as undefined,
  setup(__props: {
  lockablePool: FullPool;
  totalLpTokens: string;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { tokens } = useTokens();
const { fNum2 } = useNumbers();

/**
 * COMPUTED
 */
const poolWeightsLabel = computed(() =>
  props.lockablePool.tokens
    .map(token => {
      const weightLabel = formatWeightLabel(token.weight);
      const symbol = token.symbol ?? tokens.value[token.address].symbol;

      return `${weightLabel} ${symbol}`;
    })
    .join(' / ')
);

/**
 * METHODS
 */
function formatWeightLabel(weight: string) {
  return fNum2(weight, {
    style: 'percent',
    maximumFractionDigits: 0
  });
}

return (_ctx: any,_cache: any) => {
  const _component_BalAsset = _resolveComponent("BalAsset")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('getVeBAL.previewModal.lpTokens', [
              _unref(fNum2)(__props.totalLpTokens, _unref(FNumFormats).token)
            ])), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(poolWeightsLabel)), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.lockablePool.tokenAddresses, (tokenAddress) => {
          return (_openBlock(), _createBlock(_component_BalAsset, {
            key: tokenAddress,
            address: tokenAddress,
            size: 30
          }, null, 8, ["address"]))
        }), 128))
      ])
    ])
  ]))
}
}

})