import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["textContent"]

import { computed, onMounted, ref } from 'vue';
import { createPopper, Instance as PopperInstance } from '@popperjs/core';

import BalIcon, { IconSize } from '../BalIcon/BalIcon.vue';

type Placement = 'top' | 'left' | 'bottom' | 'right';
type TextAlign = 'left' | 'center' | 'right' | '';

type Props = {
  text?: string;
  placement?: Placement;
  onShow?: () => void;
  onHide?: () => void;
  noPad?: boolean;
  disabled?: boolean;
  iconSize?: IconSize;
  iconName?: string;
  iconClass?: string;
  width?: string;
  textAlign?: TextAlign;
};


export default _defineComponent({
  props: {
    text: { type: String, required: false, default: '' },
    placement: { type: String, required: false, default: 'top' },
    onShow: { type: Function, required: false },
    onHide: { type: Function, required: false },
    noPad: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    iconSize: { type: null, required: false, default: 'md' },
    iconName: { type: String, required: false, default: 'info' },
    iconClass: { type: String, required: false, default: 'text-gray-300' },
    width: { type: String, required: false, default: '52' },
    textAlign: { type: String, required: false, default: '' }
  } as unknown as undefined,
  setup(__props: {
  text?: string;
  placement?: Placement;
  onShow?: () => void;
  onHide?: () => void;
  noPad?: boolean;
  disabled?: boolean;
  iconSize?: IconSize;
  iconName?: string;
  iconClass?: string;
  width?: string;
  textAlign?: TextAlign;
}) {

const props = __props


const activator = ref<HTMLElement>();
const content = ref<HTMLElement>();
const popper = ref<PopperInstance>();

const tooltipClasses = computed(() => {
  return {
    'p-3': !props.noPad,
    [`w-${props.width}`]: true,
    [`text-${props.textAlign}`]: props.textAlign !== ''
  };
});

// show the tooltip
const handleMouseEnter = () => {
  if (!props.disabled && content.value && popper.value) {
    content.value.setAttribute('data-show', '');
    popper.value.update();
    props.onShow && props.onShow();
  }
};

// hide the tooltip
const handleMouseLeave = () => {
  if (!props.disabled && content.value) {
    content.value.removeAttribute('data-show');
    props.onHide && props.onHide();
  }
};

onMounted(() => {
  if (activator.value && content.value) {
    popper.value = createPopper(activator.value, content.value, {
      placement: props.placement,
      modifiers: [{ name: 'offset', options: { offset: [0, 5] } }]
    });
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", _mergeProps({
      ref: activator,
      onMouseenter: handleMouseEnter,
      onMouseleave: handleMouseLeave,
      class: ['leading-none', { 'cursor-default': __props.disabled }]
    }, _ctx.$attrs), [
      _renderSlot(_ctx.$slots, "activator", {}, () => [
        _createVNode(BalIcon, {
          name: __props.iconName,
          size: __props.iconSize,
          class: _normalizeClass(__props.iconClass)
        }, null, 8, ["name", "size", "class"])
      ])
    ], 16),
    _createElementVNode("div", _mergeProps({
      ref: content,
      class: ["tooltip text-xs text-black dark:text-white bg-white dark:bg-gray-800 font-medium shadow rounded-md border dark:border-gray-900 z-50", _unref(tooltipClasses)]
    }, _ctx.$attrs), [
      (__props.text)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            textContent: _toDisplayString(__props.text)
          }, null, 8, _hoisted_1))
        : _renderSlot(_ctx.$slots, "default", { key: 1 })
    ], 16)
  ], 64))
}
}

})