import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-center"
}
const _hoisted_2 = { class: "font-semibold text-lg" }

import LockForm from '@/components/forms/lock_actions/LockForm/LockForm.vue';

import { isVeBalSupported } from '@/composables/useVeBAL';

export default _defineComponent({
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_unref(isVeBalSupported))
    ? (_openBlock(), _createBlock(LockForm, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('veBAL.notSupported.title')), 1),
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('veBAL.notSupported.description')), 1)
      ]))
}
}

})