"use strict";
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LidoRelayer__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        inputs: [
            {
                internalType: "contract IVault",
                name: "vault",
                type: "address",
            },
            {
                internalType: "contract IwstETH",
                name: "wstETH",
                type: "address",
            },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        inputs: [
            {
                internalType: "enum IVault.SwapKind",
                name: "kind",
                type: "uint8",
            },
            {
                components: [
                    {
                        internalType: "bytes32",
                        name: "poolId",
                        type: "bytes32",
                    },
                    {
                        internalType: "uint256",
                        name: "assetInIndex",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "assetOutIndex",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "bytes",
                        name: "userData",
                        type: "bytes",
                    },
                ],
                internalType: "struct IVault.BatchSwapStep[]",
                name: "swaps",
                type: "tuple[]",
            },
            {
                internalType: "contract IAsset[]",
                name: "assets",
                type: "address[]",
            },
            {
                components: [
                    {
                        internalType: "address",
                        name: "sender",
                        type: "address",
                    },
                    {
                        internalType: "bool",
                        name: "fromInternalBalance",
                        type: "bool",
                    },
                    {
                        internalType: "address payable",
                        name: "recipient",
                        type: "address",
                    },
                    {
                        internalType: "bool",
                        name: "toInternalBalance",
                        type: "bool",
                    },
                ],
                internalType: "struct IVault.FundManagement",
                name: "funds",
                type: "tuple",
            },
            {
                internalType: "int256[]",
                name: "limits",
                type: "int256[]",
            },
            {
                internalType: "uint256",
                name: "deadline",
                type: "uint256",
            },
        ],
        name: "batchSwap",
        outputs: [
            {
                internalType: "int256[]",
                name: "swapAmounts",
                type: "int256[]",
            },
        ],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "poolId",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "sender",
                type: "address",
            },
            {
                internalType: "address payable",
                name: "recipient",
                type: "address",
            },
            {
                components: [
                    {
                        internalType: "contract IAsset[]",
                        name: "assets",
                        type: "address[]",
                    },
                    {
                        internalType: "uint256[]",
                        name: "minAmountsOut",
                        type: "uint256[]",
                    },
                    {
                        internalType: "bytes",
                        name: "userData",
                        type: "bytes",
                    },
                    {
                        internalType: "bool",
                        name: "toInternalBalance",
                        type: "bool",
                    },
                ],
                internalType: "struct IVault.ExitPoolRequest",
                name: "request",
                type: "tuple",
            },
        ],
        name: "exitPool",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "getStETH",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getVault",
        outputs: [
            {
                internalType: "contract IVault",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getWstETH",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "poolId",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "sender",
                type: "address",
            },
            {
                internalType: "address",
                name: "recipient",
                type: "address",
            },
            {
                components: [
                    {
                        internalType: "contract IAsset[]",
                        name: "assets",
                        type: "address[]",
                    },
                    {
                        internalType: "uint256[]",
                        name: "maxAmountsIn",
                        type: "uint256[]",
                    },
                    {
                        internalType: "bytes",
                        name: "userData",
                        type: "bytes",
                    },
                    {
                        internalType: "bool",
                        name: "fromInternalBalance",
                        type: "bool",
                    },
                ],
                internalType: "struct IVault.JoinPoolRequest",
                name: "request",
                type: "tuple",
            },
        ],
        name: "joinPool",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                components: [
                    {
                        internalType: "bytes32",
                        name: "poolId",
                        type: "bytes32",
                    },
                    {
                        internalType: "enum IVault.SwapKind",
                        name: "kind",
                        type: "uint8",
                    },
                    {
                        internalType: "contract IAsset",
                        name: "assetIn",
                        type: "address",
                    },
                    {
                        internalType: "contract IAsset",
                        name: "assetOut",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "bytes",
                        name: "userData",
                        type: "bytes",
                    },
                ],
                internalType: "struct IVault.SingleSwap",
                name: "singleSwap",
                type: "tuple",
            },
            {
                components: [
                    {
                        internalType: "address",
                        name: "sender",
                        type: "address",
                    },
                    {
                        internalType: "bool",
                        name: "fromInternalBalance",
                        type: "bool",
                    },
                    {
                        internalType: "address payable",
                        name: "recipient",
                        type: "address",
                    },
                    {
                        internalType: "bool",
                        name: "toInternalBalance",
                        type: "bool",
                    },
                ],
                internalType: "struct IVault.FundManagement",
                name: "funds",
                type: "tuple",
            },
            {
                internalType: "uint256",
                name: "limit",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "deadline",
                type: "uint256",
            },
        ],
        name: "swap",
        outputs: [
            {
                internalType: "uint256",
                name: "swapAmount",
                type: "uint256",
            },
        ],
        stateMutability: "payable",
        type: "function",
    },
    {
        stateMutability: "payable",
        type: "receive",
    },
];
class LidoRelayer__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.LidoRelayer__factory = LidoRelayer__factory;
LidoRelayer__factory.abi = _abi;
