import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full max-w-2xl mx-auto" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = {
  key: 1,
  class: "text-3xl font-bold text-white mb-1"
}
const _hoisted_4 = {
  key: 2,
  class: "relative mt-2 inline-block"
}
const _hoisted_5 = ["textContent"]
const _hoisted_6 = { class: "flex justify-center mt-4" }

import { computed } from 'vue';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import usePools from '@/composables/pools/usePools';
import { EXTERNAL_LINKS } from '@/constants/links';
import useFathom from '@/composables/useFathom';
import useWeb3 from '@/services/web3/useWeb3';
import useDarkMode from '@/composables/useDarkMode';
import { useLock } from '@/composables/useLock';
import { bnum } from '@/lib/utils';
import { useRouter } from 'vue-router';
import useStaking from '@/composables/staking/useStaking';
import { isL2 } from '@/composables/useNetwork';

/**
 * COMPOSABLES
 */

export default _defineComponent({
  setup(__props) {

const router = useRouter();
const { fNum2 } = useNumbers();
const {
  isWalletReady,
  toggleWalletSelectModal,
  isWalletConnecting
} = useWeb3();
const { trackGoal, Goals } = useFathom();
const { totalInvestedAmount, isLoadingUserPools } = usePools();
const { darkMode } = useDarkMode();
const { lockFiatValue, isLoadingLock } = useLock();
const {
  totalStakedFiatValue,
  isLoading: isStakingLoading,
  isStakingQueryEnabled
} = useStaking();

/**
 * COMPUTED
 */
const classes = computed(() => ({
  ['h-72']: !isWalletReady.value && !isWalletConnecting.value,
  ['h-40']: isWalletReady.value || isWalletConnecting.value
}));

const totalInvestedLabel = computed((): string => {
  const value = bnum(totalInvestedAmount.value || '0')
    .plus(lockFiatValue.value)
    .plus(totalStakedFiatValue.value)
    .toString();
  return fNum2(value, FNumFormats.fiat);
});

const totalVeBalLabel = computed((): string =>
  fNum2(lockFiatValue.value, FNumFormats.fiat)
);

const isLoadingLockAndStaking = computed(
  (): boolean =>
    !isL2.value &&
    (isLoadingLock.value ||
      (isStakingQueryEnabled.value && isStakingLoading.value))
);

const isLoadingTotalValue = computed(
  (): boolean => isLoadingUserPools.value || isLoadingLockAndStaking.value
);

/**
 * METHODS
 */
function onClickConnect() {
  toggleWalletSelectModal(true);
  trackGoal(Goals.ClickHeroConnectWallet);
}

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalIcon = _resolveComponent("BalIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['app-hero', _unref(classes)])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_unref(isWalletReady) || _unref(isWalletConnecting))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("h1", {
              textContent: _toDisplayString(_ctx.$t('myBalancerInvestments')),
              class: "text-base font-medium text-white opacity-90 font-body mb-2"
            }, null, 8, _hoisted_2),
            (_unref(isLoadingTotalValue))
              ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
                  key: 0,
                  class: "h-10 w-40 mx-auto",
                  white: ""
                }))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(totalInvestedLabel)), 1)),
            (!_unref(isL2))
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_unref(isLoadingTotalValue))
                    ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
                        key: 0,
                        class: "h-8 w-40 mx-auto",
                        white: ""
                      }))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: "\n              vebal-banner\n              h-8\n              flex\n              items-center\n              px-3\n              text-yellow-500 text-sm\n              font-medium\n              cursor-pointer\n              border border-yellow-500\n              rounded-bl rounded-tr\n            ",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push({ name: 'vebal' })))
                      }, _toDisplayString(_ctx.$t('inclXInVeBal', [_unref(totalVeBalLabel)])), 1))
                ]))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("h1", {
              textContent: _toDisplayString(_ctx.$t('ammPlatform')),
              class: "\n            text-white text-center text-4xl\n            md:text-5xl\n            pb-2\n            font-display font-black\n          "
            }, null, 8, _hoisted_5),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_BalBtn, {
                color: _unref(darkMode) ? 'gray' : 'white',
                class: "mr-3",
                onClick: onClickConnect
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('connectWallet')), 1)
                ]),
                _: 1
              }, 8, ["color"]),
              _createVNode(_component_BalBtn, {
                tag: "a",
                href: _unref(EXTERNAL_LINKS).Balancer.Home,
                target: "_blank",
                rel: "noreferrer",
                color: "white",
                outline: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(trackGoal)(_unref(Goals).ClickHeroLearnMore)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('learnMore')) + " ", 1),
                  _createVNode(_component_BalIcon, {
                    name: "arrow-up-right",
                    size: "sm",
                    class: "ml-1"
                  })
                ]),
                _: 1
              }, 8, ["href"])
            ])
          ], 64))
    ])
  ], 2))
}
}

})