import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 w-full bg-gray-50 dark:bg-gray-800 border-t border-gray-200 dark:border-gray-900" }
const _hoisted_2 = { class: "text-gray-500 text-sm" }
const _hoisted_3 = { class: "flex justify-between items-center mb-4" }
const _hoisted_4 = {
  key: 1,
  class: "grid gap-2 grid-cols-2"
}

import { toRef, computed } from 'vue';
import useWithdrawMath from '@/components/forms/pool_actions/WithdrawForm/composables/useWithdrawMath';
import { FullPool } from '@/services/balancer/subgraph/types';
import useTokens from '@/composables/useTokens';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { bnum } from '@/lib/utils';
import useWeb3 from '@/services/web3/useWeb3';
import { lpTokensFor } from '@/composables/usePool';

/**
 * TYPES
 */
type Props = {
  pool: FullPool;
  missingPrices: boolean;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    missingPrices: { type: Boolean, required: true }
  } as unknown as undefined,
  setup(__props: {
  pool: FullPool;
  missingPrices: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { hasBpt } = useWithdrawMath(toRef(props, 'pool'));
const { balanceFor, nativeAsset, wrappedNativeAsset } = useTokens();
const { fNum2, toFiat } = useNumbers();
const { isWalletReady, toggleWalletSelectModal } = useWeb3();

/**
 * COMPUTED
 */
const fiatTotal = computed(() => {
  const fiatValue = lpTokensFor(props.pool)
    .map(address => {
      let tokenBalance = '0';

      console.log(wrappedNativeAsset);

      if (address === wrappedNativeAsset.value.address) {
        const wrappedBalance = balanceFor(address);
        const nativeBalance = balanceFor(nativeAsset.address);
        tokenBalance = bnum(nativeBalance).gt(wrappedBalance)
          ? nativeBalance
          : wrappedBalance;
      } else {
        tokenBalance = balanceFor(address);
      }

      return toFiat(tokenBalance, address);
    })
    .reduce((total, value) =>
      bnum(total)
        .plus(value)
        .toString()
    );

  return fNum2(fiatValue, FNumFormats.fiat);
});

return (_ctx: any,_cache: any) => {
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('basedOnTokensInWallet')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h5", null, _toDisplayString(_ctx.$t('youCanInvest')), 1),
      _createElementVNode("h5", null, _toDisplayString(_unref(isWalletReady) ? _unref(fiatTotal) : '-'), 1)
    ]),
    (!_unref(isWalletReady))
      ? (_openBlock(), _createBlock(_component_BalBtn, {
          key: 0,
          label: _ctx.$t('connectWallet'),
          color: "gradient",
          block: "",
          onClick: _unref(toggleWalletSelectModal)
        }, null, 8, ["label", "onClick"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_BalBtn, {
            tag: "router-link",
            to: { name: 'invest' },
            label: _ctx.$t('invest'),
            color: "gradient",
            block: ""
          }, null, 8, ["label"]),
          _createVNode(_component_BalBtn, {
            tag: _unref(hasBpt) ? 'router-link' : 'div',
            to: { name: 'withdraw' },
            label: _ctx.$t('withdraw.label'),
            disabled: !_unref(hasBpt),
            block: ""
          }, null, 8, ["tag", "label", "disabled"])
        ]))
  ]))
}
}

})