import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-42b04646")
const _hoisted_1 = { class: "w-full bg-gray-850 hero-container" }
const _hoisted_2 = { class: "hero-content" }
const _hoisted_3 = { class: "hero-text py-8 lg:py-4 xl:pt-0 px-4 lg:px-8 2xl:px-0 max-w-md" }
const _hoisted_4 = { class: "text-white font-medium mb-3" }
const _hoisted_5 = { class: "title mb-6 text-white" }
const _hoisted_6 = { class: "flex mt-6" }
const _hoisted_7 = { class: "py-8 px-4 lg:pl-8" }
const _hoisted_8 = { class: "coins flex" }
const _hoisted_9 = { class: "coin group" }
const _hoisted_10 = { class: "caption" }
const _hoisted_11 = { class: "inline mr-1" }
const _hoisted_12 = { class: "coin group" }
const _hoisted_13 = { class: "caption" }
const _hoisted_14 = { class: "inline mr-1" }
const _hoisted_15 = { class: "coin group" }
const _hoisted_16 = { class: "caption" }
const _hoisted_17 = { class: "inline mr-1" }
_popScopeId()

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

/**
 * COMPOSABLES
 */

export default _defineComponent({
  setup(__props) {

const { t } = useI18n();
const router = useRouter();

/**
 * COMPUTED
 */
const benefits = computed(() => [
  t('veBAL.hero.benefits.boost'),
  t('veBAL.hero.benefits.vote'),
  t('veBAL.hero.benefits.earn')
]);

/**
 * METHODS
 */
function navigateToGetVeBAL() {
  router.push({
    name: 'get-vebal',
    query: {
      returnRoute: 'vebal'
    }
  });
}

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalImage = _resolveComponent("BalImage")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('veBAL.hero.eyebrow')), 1),
        _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('veBAL.hero.title')), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(benefits), (benefit, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: "flex items-center mb-2 text-white"
          }, [
            _createVNode(_component_BalIcon, {
              name: "check",
              class: "text-green-500 mr-2"
            }),
            _createTextVNode(" " + _toDisplayString(benefit), 1)
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_BalBtn, {
            color: "gradient",
            onClick: navigateToGetVeBAL,
            class: "mr-3 hero-btn"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('veBAL.hero.buttons.getVeBAL')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_BalBtn, {
            tag: "a",
            href: "https://forum.balancer.fi/t/introducing-vebal-tokenomics/2512",
            target: "_blank",
            rel: "noreferrer",
            color: "white",
            class: "hero-btn",
            outline: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('veBAL.hero.buttons.learnMore')) + " ", 1),
              _createVNode(_component_BalIcon, {
                name: "arrow-up-right",
                size: "sm",
                class: "ml-px group-hover:text-pink-500 transition-colors"
              })
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_BalImage, {
              class: "graphic",
              width: "724",
              height: "800",
              src: require('@/assets/images/coins-1.png'),
              alt: ""
            }, null, 8, ["src"]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('veBAL.hero.tokens.balWETH')), 1),
              _createVNode(_component_BalTooltip, {
                iconSize: "xs",
                textAlign: "left",
                class: "mt-1"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('veBAL.hero.tokenInfo.balWETH')), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_BalImage, {
              class: "graphic",
              width: "696",
              height: "800",
              src: require('@/assets/images/coins-2.png'),
              alt: ""
            }, null, 8, ["src"]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t('veBAL.hero.tokens.lpToken')), 1),
              _createVNode(_component_BalTooltip, {
                iconSize: "xs",
                textAlign: "left",
                class: "mt-1"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('veBAL.hero.tokenInfo.lpToken')), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_BalImage, {
              class: "graphic",
              width: "696",
              height: "800",
              src: require('@/assets/images/coins-3.png'),
              alt: ""
            }, null, 8, ["src"]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t('veBAL.hero.tokens.veBAL')), 1),
              _createVNode(_component_BalTooltip, {
                iconSize: "xs",
                textAlign: "left",
                class: "mt-1"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('veBAL.hero.tokenInfo.veBAL')), 1)
                ]),
                _: 1
              })
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})