import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

import { computed, ref } from 'vue';

import useUserPoolsQuery from '@/composables/queries/useUserPoolsQuery';
import useStaking from '@/composables/staking/useStaking';

import {
  DecoratedPool,
  DecoratedPoolWithShares,
  FullPool
} from '@/services/balancer/subgraph/types';
import { bnum } from '@/lib/utils';

import PoolsTable from '@/components/tables/PoolsTable/PoolsTable.vue';
import StakePreviewModal from '../../stake/StakePreviewModal.vue';

import { uniqBy } from 'lodash';
import { isMigratablePool } from '@/composables/usePool';
import { isL2 } from '@/composables/useNetwork';

/** STATE */

export default _defineComponent({
  setup(__props) {

const showStakeModal = ref(false);
const stakePool = ref<FullPool | undefined>();

/** COMPOSABLES */
const {
  userGaugeShares,
  userLiquidityGauges,
  stakedPools,
  isLoadingStakingData,
  setPoolAddress
} = useStaking();

/** COMPUTED */
// a map of poolId-stakedBPT for the connected user
const stakedBalanceMap = computed(() => {
  const map: Record<string, string> = {};
  if (!userGaugeShares.value) return map;
  for (const gaugeShare of userGaugeShares.value) {
    map[gaugeShare.gauge.poolId] = gaugeShare.balance;
  }
  return map;
});

// first retrieve all the pools the user has liquidity for
const {
  data: userPools,
  isLoading: isLoadingUserPools,
  isIdle: isUserPoolsIdle
} = useUserPoolsQuery();

const partiallyStakedPools = computed(() => {
  const stakedPoolIds = stakedPools.value?.map(pool => pool.id);
  // The pools which are both staked, but also have BPT available for staking
  // NOTE: we are iterating through user pools here so we can access the bpt var
  return (userPools.value?.pools || [])
    .filter(pool => {
      return stakedPoolIds?.includes(pool.id);
    })
    .map(pool => {
      // calculate the staked percentage by using the staked balance
      // pulled from the gauge subgraph
      const stakedBalance = stakedBalanceMap.value[pool.id];
      const unstakedBalance = pool.bpt;
      const stakedPct = bnum(stakedBalance).div(
        bnum(stakedBalance).plus(unstakedBalance)
      );
      return {
        ...pool,
        stakedPct: stakedPct.toString(),
        stakedShares: calculateFiatValueOfShares(pool, stakedBalance)
      };
    });
});

// Pools where there is no staked BPT at all
const unstakedPools = computed(() => {
  const availableGaugePoolIds = (userLiquidityGauges.value || []).map(
    gauge => gauge.poolId
  );
  return (userPools.value?.pools || [])
    .filter(pool => {
      return availableGaugePoolIds?.includes(pool.id);
    })
    .map(pool => ({
      ...pool,
      stakedPct: '0',
      stakedShares: '0'
    }));
});

const poolsToRender = computed(() => {
  const stakablePools = [...partiallyStakedPools.value, ...unstakedPools.value];
  const stakableUserPoolIds = stakablePools.map(pool => pool.id);
  const nonMigratableUserPools = (userPools.value?.pools || [])
    .filter(pool => !isMigratablePool(pool))
    .filter(pool => !stakableUserPoolIds.includes(pool.id));
  // now mash them together
  return uniqBy([...nonMigratableUserPools, ...stakablePools], pool => pool.id);
});

const hiddenColumns = computed((): string[] => {
  const hiddenCols = ['poolVolume', 'poolValue', 'migrate'];
  if (isL2.value) hiddenCols.push('stake');
  return hiddenCols;
});

/** METHODS */
function handleStake(pool: FullPool) {
  setPoolAddress(pool.address);
  showStakeModal.value = true;
  stakePool.value = pool;
}

function calculateFiatValueOfShares(
  pool: DecoratedPoolWithShares | DecoratedPool,
  stakedBalance: string
) {
  return bnum(pool.totalLiquidity)
    .div(pool.totalShares)
    .times((stakedBalance || '0').toString())
    .toString();
}

function handleModalClose() {
  showStakeModal.value = false;
}

return (_ctx: any,_cache: any) => {
  const _component_BalStack = _resolveComponent("BalStack")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BalStack, {
      vertical: "",
      spacing: "sm"
    }, {
      default: _withCtx(() => [
        (!_unref(isL2))
          ? (_openBlock(), _createElementBlock("h5", _hoisted_1, _toDisplayString(_ctx.$t('staking.unstakedPools')), 1))
          : _createCommentVNode("", true),
        _createVNode(PoolsTable, {
          key: _unref(poolsToRender),
          isLoading: _unref(isLoadingStakingData) || _unref(isLoadingUserPools) || _unref(isUserPoolsIdle),
          data: _unref(poolsToRender),
          noPoolsLabel: _ctx.$t('noInvestments'),
          hiddenColumns: _unref(hiddenColumns),
          onTriggerStake: handleStake,
          showPoolShares: ""
        }, null, 8, ["isLoading", "data", "noPoolsLabel", "hiddenColumns"])
      ]),
      _: 1
    }),
    _createVNode(StakePreviewModal, {
      pool: stakePool.value,
      isVisible: showStakeModal.value,
      onClose: handleModalClose,
      action: "stake"
    }, null, 8, ["pool", "isVisible"])
  ], 64))
}
}

})