import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

import { ref, computed, watch } from 'vue';
import {
  TransactionReceipt,
  TransactionResponse
} from '@ethersproject/abstract-provider';
import { Step, StepState } from '@/types';
import {
  TransactionAction,
  TransactionActionInfo,
  TransactionActionState
} from '@/types/transactions';
import useEthers from '@/composables/useEthers';
import { dateTimeLabelFor } from '@/composables/useTime';
import useTransactionErrors from '@/composables/useTransactionErrors';
import { configService } from '@/services/config/config.service';
import { ChainId } from '@aave/protocol-js';
import AnimatePresence from '@/components/animate/AnimatePresence.vue';

/**
 * TYPES
 */
type Props = {
  actions: TransactionActionInfo[];
  disabled?: boolean;
  // override action state loading prop and show
  // loading for all steps
  isLoading?: boolean;
  // override action state loading label
  // for all steps
  loadingLabel?: string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    actions: { type: Array, required: true },
    disabled: { type: Boolean, required: false, default: false },
    isLoading: { type: Boolean, required: false, default: false },
    loadingLabel: { type: String, required: false, default: '' }
  } as unknown as undefined,
  emits: ["success"] as unknown as undefined,
  setup(__props: {
  actions: TransactionActionInfo[];
  disabled?: boolean;
  // override action state loading prop and show
  // loading for all steps
  isLoading?: boolean;
  // override action state loading label
  // for all steps
  loadingLabel?: string;
}, { emit }: { emit: ({
  (e: 'success', value: any): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props
/**
 * A series of actions the user must perform, displayed horizontally as a series of dots
 * As each action is in progress or completed the dot changes to reflect its
 * current state.
 *
 * Useful if there are an arbitrary number of actions the user must take such as
 * "approve n tokens, then invest in a pool.""
 */




const defaultActionState: TransactionActionState = {
  init: false,
  confirming: false,
  confirmed: false,
  confirmedAt: ''
};

/**
 * STATE
 */
const currentActionIndex = ref(0);
const _actions = ref<TransactionActionInfo[]>(props.actions);

const actionStates = ref(
  _actions.value.map(() => ({
    ...defaultActionState
  }))
);

/**
 * WATCHERS
 */
watch(
  () => [props.actions, props.isLoading],
  () => {
    _actions.value = props.actions;
    actionStates.value = _actions.value.map(() => ({
      ...defaultActionState
    }));
  },
  {
    deep: true
  }
);

/**
 * COMPOSABLES
 */
const { txListener, getTxConfirmedAt } = useEthers();
const { parseError } = useTransactionErrors();

/**
 * COMPUTED
 */

const actions = computed((): TransactionAction[] => {
  return _actions.value.map((actionInfo, idx) => {
    const actionState = actionStates.value[idx];
    return {
      label: actionInfo.label,
      loadingLabel: actionState.init
        ? actionInfo.loadingLabel
        : actionInfo.confirmingLabel,
      pending: actionState.init || actionState.confirming,
      promise: submit.bind(null, actionInfo.action, actionState),
      step: {
        tooltip: actionInfo.stepTooltip,
        state: getStepState(actionState, idx)
      }
    };
  });
});

const currentAction = computed(
  (): TransactionAction => actions.value[currentActionIndex.value]
);

const currentActionState = computed(
  (): TransactionActionState => actionStates.value[currentActionIndex.value]
);

const lastActionState = computed(
  (): TransactionActionState =>
    actionStates.value[actionStates.value.length - 1]
);

const steps = computed((): Step[] => actions.value.map(action => action.step));

const spacerWidth = computed((): number => {
  return 13 - steps.value.length;
});

/**
 * METHODS
 */

function getStepState(
  actionState: TransactionActionState,
  index: number
): StepState {
  if (currentActionIndex.value < index) return StepState.Todo;
  else if (actionState.confirming) return StepState.Pending;
  else if (actionState.init) return StepState.WalletOpen;
  else if (actionState.confirmed) return StepState.Success;
  return StepState.Active;
}

async function submit(
  action: () => Promise<TransactionResponse>,
  state: TransactionActionState
): Promise<void> {
  try {
    state.init = true;
    state.error = null;

    const tx = await action();

    state.init = false;
    state.confirming = true;

    handleTransaction(tx, state);
  } catch (error) {
    state.init = false;
    state.confirming = false;
    state.error = parseError(error);
    console.error(error);
  }
}

async function handleTransaction(
  tx: TransactionResponse,
  state: TransactionActionState
): Promise<void> {
  await txListener(tx, {
    onTxConfirmed: async (receipt: TransactionReceipt) => {
      state.receipt = receipt;

      // need to explicity wait for a number of confirmations
      // on polygon
      if (Number(configService.network.chainId) === ChainId.polygon) {
        await tx.wait(10);
      }

      const confirmedAt = await getTxConfirmedAt(receipt);
      state.confirmedAt = dateTimeLabelFor(confirmedAt);

      if (currentActionIndex.value >= actions.value.length - 1) {
        emit('success', { receipt, confirmedAt: state.confirmedAt });
      } else {
        currentActionIndex.value += 1;
      }

      state.confirming = false;
      state.confirmed = true;
    },
    onTxFailed: () => {
      state.confirming = false;
    }
  });
}

return (_ctx: any,_cache: any) => {
  const _component_BalAlert = _resolveComponent("BalAlert")!
  const _component_BalHorizSteps = _resolveComponent("BalHorizSteps")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalStack = _resolveComponent("BalStack")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(AnimatePresence, { isVisible: "" }, {
      default: _withCtx(() => [
        (_unref(currentActionState)?.error && !__props.isLoading)
          ? (_openBlock(), _createBlock(_component_BalAlert, {
              key: 0,
              type: "error",
              title: _unref(currentActionState)?.error?.title,
              description: _unref(currentActionState)?.error?.description,
              block: "",
              class: "mb-4"
            }, null, 8, ["title", "description"]))
          : _createCommentVNode("", true),
        _createVNode(_component_BalStack, { vertical: "" }, {
          default: _withCtx(() => [
            (_unref(actions).length > 1 && !_unref(lastActionState)?.confirmed)
              ? (_openBlock(), _createBlock(_component_BalHorizSteps, {
                  key: 0,
                  steps: _unref(steps),
                  spacerWidth: _unref(spacerWidth),
                  class: "flex justify-center"
                }, null, 8, ["steps", "spacerWidth"]))
              : _createCommentVNode("", true),
            (!_unref(lastActionState)?.confirmed)
              ? (_openBlock(), _createBlock(_component_BalBtn, {
                  key: 1,
                  disabled: props.disabled,
                  color: "gradient",
                  loading: _unref(currentAction)?.pending || __props.isLoading,
                  "loading-label": 
            __props.isLoading
              ? __props.loadingLabel || _ctx.$t('loading')
              : _unref(currentAction)?.loadingLabel
          ,
                  block: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(currentAction).promise()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(currentAction)?.label), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "loading", "loading-label"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})