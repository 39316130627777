import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "p-4 rounded-xl w-full hover:bg-gray-50 dark:hover:bg-gray-800" }
const _hoisted_2 = { class: "p-4" }

import AnimatePresence from '@/components/animate/AnimatePresence.vue';
import { configService } from '@/services/config/config.service';
import LiquidityMiningDistributions from '@/lib/utils/liquidityMining/MultiTokenLiquidityMining.json';
import { last } from 'lodash';

type Props = {
  poolId: string;
};


export default _defineComponent({
  props: {
    poolId: { type: String, required: true }
  } as unknown as undefined,
  setup(__props: {
  poolId: string;
}) {

const props = __props


const latestWeek = last(Object.keys(LiquidityMiningDistributions)) as string;
const relevantDistribution = LiquidityMiningDistributions[latestWeek].find(
  distribution => distribution.chainId === configService.network.chainId
);

const isEligibleForLM = (relevantDistribution.pools[props.poolId] || []).some(
  token => token.amount > 0
);

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalStack = _resolveComponent("BalStack")!
  const _component_BalAccordion = _resolveComponent("BalAccordion")!

  return (_openBlock(), _createBlock(AnimatePresence, { isVisible: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_BalAccordion, {
        class: "shadow-2xl rounded-xl",
        sections: [
        {
          title: _ctx.$t('liquidityMiningIncentives'),
          id: 'lm-incentives',
          handle: 'lm-handle',
          isDisabled: !_unref(isEligibleForLM)
        }
      ]
      }, {
        "lm-handle": _withCtx(() => [
          _createElementVNode("button", _hoisted_1, [
            _createVNode(_component_BalStack, {
              horizontal: "",
              justify: "between",
              align: "center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BalStack, {
                  spacing: "sm",
                  align: "center"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass([
                  'flex items-center p-1 text-white rounded-full',
                  {
                    'bg-green-500': _unref(isEligibleForLM),
                    'bg-gray-400': !_unref(isEligibleForLM)
                  }
                ])
                    }, [
                      (_unref(isEligibleForLM))
                        ? (_openBlock(), _createBlock(_component_BalIcon, {
                            key: 0,
                            size: "sm",
                            name: "check"
                          }))
                        : (_openBlock(), _createBlock(_component_BalIcon, {
                            key: 1,
                            size: "sm",
                            name: "x"
                          }))
                    ], 2),
                    _createElementVNode("h6", null, _toDisplayString(_ctx.$t('liquidityMiningIncentives')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_BalStack, {
                  horizontal: "",
                  spacing: "sm",
                  align: "center"
                }, {
                  default: _withCtx(() => [
                    (_unref(isEligibleForLM))
                      ? (_openBlock(), _createBlock(_component_BalIcon, {
                          key: 0,
                          name: "chevron-down",
                          class: "text-blue-500"
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        "lm-incentives": _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('liquidityMiningIncentivesCopy', [
                _unref(configService).network.shortName
              ])), 1)
          ])
        ]),
        _: 1
      }, 8, ["sections"])
    ]),
    _: 1
  }))
}
}

})