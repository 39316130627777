
import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
  computed
} from 'vue';
import { sortBy, sumBy, tail, compact } from 'lodash';

type Sticky = 'horizontal' | 'vertical' | 'both';
type Data = any;

type InitialState = {
  sortDirection: 'asc' | 'desc' | null;
  sortColumn: string | null;
};

export type ColumnDefinition<T = Data> = {
  // Column Header Label
  name: string;
  // Unique ID
  id: string;
  // Key or function to access data from the row
  accessor: string | ((row: T) => string);
  // Slot ID for custom rendering the cell
  Cell?: string;
  // Slot ID for custom rendering a header
  Header?: string;
  // Is the column sortable
  isSortable?: boolean;
  // Extra classes to supply to the column. E.g. min width
  className?: string;
  // Left or right aligned content. E.g. Numbers should be right aligned
  align?: 'left' | 'right' | 'center';
  // Should the column width grow to fit available space?
  noGrow?: boolean;
  // Set to true to hide the column
  hidden?: boolean;
  // Accessor for sorting purposes
  sortKey?: string | ((row: T) => unknown);

  width?: number;

  totalsCell?: string;

  cellClassName?: string;
};

export default defineComponent({
  name: 'BalTable',

  emits: ['loadMore'],

  props: {
    columns: {
      type: Object as PropType<ColumnDefinition[]>,
      required: true
    },
    data: {
      type: Object as PropType<Data[]>
    },
    isLoading: {
      type: Boolean,
      default: () => false
    },
    isLoadingMore: {
      type: Boolean,
      default: false
    },
    skeletonClass: {
      type: String
    },
    onRowClick: {
      type: Function as PropType<(data: Data) => void>
    },
    sticky: {
      type: String as PropType<Sticky>
    },
    square: {
      type: Boolean,
      default: false
    },
    isPaginated: {
      type: Boolean,
      default: false
    },
    noResultsLabel: {
      type: String
    },
    link: {
      type: Object,
      default: null
    },
    initialState: {
      type: Object as PropType<InitialState>,
      default: () => ({
        sortColumn: null,
        sortDirection: null
      })
    }
  },
  setup(props) {
    const stickyHeaderRef = ref();
    const tableRef = ref<HTMLElement>();
    const isColumnStuck = ref(false);
    const tableData = ref(props.data);
    const currentSortDirection = ref<InitialState['sortDirection']>(
      props.initialState.sortDirection
    );
    const currentSortColumn = ref<InitialState['sortColumn']>(
      props.initialState.sortColumn
    );
    const headerRef = ref<HTMLElement>();
    const bodyRef = ref<HTMLElement>();

    // for loading and no results
    const placeholderBlockWidth = computed(() => sumBy(props.columns, 'width'));

    const setHeaderRef = (columnIndex: number) => (el: HTMLElement) => {
      if (el && columnIndex === 0) {
        stickyHeaderRef.value = el;
      }
    };

    // Need a method for horizontal stickiness as we need to
    // check whether the table item belongs in the first column
    const getHorizontalStickyClass = (index: number) => {
      if (index !== 0) return '';
      if (props.sticky === 'horizontal' || props.sticky === 'both') {
        return 'horizontalSticky';
      }
      return '';
    };

    const handleRowClick = (data: Data) => {
      if (props.link?.to) return;
      props.onRowClick && props.onRowClick(data);
    };

    const handleSort = (columnId: string | null, updateDirection = true) => {
      const column = props.columns.find(column => column.id === columnId);
      if (!column?.sortKey) return;
      if (columnId !== currentSortColumn.value)
        currentSortDirection.value = null;

      currentSortColumn.value = columnId;

      if (updateDirection) {
        if (currentSortDirection.value === null) {
          currentSortDirection.value = 'desc';
        } else if (currentSortDirection.value === 'desc') {
          currentSortDirection.value = 'asc';
        } else {
          currentSortDirection.value = null;
        }
      }

      const sortedData = sortBy(
        (props.data as any).value || props.data,
        column.sortKey
      );
      if (currentSortDirection.value === 'asc') {
        tableData.value = sortedData;
        return;
      } else if (currentSortDirection.value === 'desc') {
        tableData.value = sortedData.reverse();
        return;
      }
      tableData.value = props.data;
    };

    function getAlignProperty(align: 'left' | 'right' | 'center' | undefined) {
      switch (align) {
        case 'left':
          return 'justify-start';
        case 'right':
          return 'justify-end';
        case 'center':
          return 'justify-center';
        default:
          return 'justify-start';
      }
    }

    onMounted(() => {
      if (bodyRef.value) {
        bodyRef.value.onscroll = () => {
          if (bodyRef.value) {
            const offsetRatio =
              bodyRef.value.offsetWidth /
              stickyHeaderRef.value.offsetWidth /
              10;
            isColumnStuck.value = !!(
              stickyHeaderRef.value.offsetLeft >
              stickyHeaderRef.value.offsetWidth * offsetRatio
            );
          }
        };
        bodyRef.value.addEventListener('scroll', () => {
          if (bodyRef.value && headerRef.value) {
            headerRef.value.scrollLeft = bodyRef.value.scrollLeft;
          }
        });
      }
    });

    const filteredColumns = computed(() =>
      props.columns.filter(column => !column.hidden)
    );

    const shouldRenderTotals = computed(() =>
      props.columns.some(column => column.totalsCell !== undefined)
    );

    watch(
      () => props.data,
      newData => {
        if (currentSortColumn.value && currentSortDirection.value !== null) {
          handleSort(currentSortColumn.value, false);
          return;
        }
        tableData.value = newData;
      }
    );

    return {
      //refs
      tableRef,
      headerRef,
      bodyRef,

      // methods
      setHeaderRef,
      getHorizontalStickyClass,
      handleSort,
      handleRowClick,
      tail,
      compact,
      getAlignProperty,

      //data
      isColumnStuck,
      tableData,
      currentSortColumn,
      currentSortDirection,
      placeholderBlockWidth,

      // computed
      filteredColumns,
      shouldRenderTotals
    };
  }
});
