import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = {
  key: 0,
  class: "px-6 py-4"
}
const _hoisted_3 = { class: "w-8 h-8 rounded shadow-sm bg-gray-50 dark:bg-gray-800 flex items-center justify-center" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = {
  key: 0,
  class: "px-6 py-4"
}
const _hoisted_7 = {
  key: 0,
  class: "px-6 py-4 flex items-center"
}
const _hoisted_8 = {
  key: 0,
  class: "px-6 py-4 text-right"
}
const _hoisted_9 = {
  key: 0,
  class: "px-4"
}

import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import BigNumber from 'bignumber.js';
import { scale } from '@/lib/utils';
import useNumbers from '@/composables/useNumbers';
import useBreakpoints from '@/composables/useBreakpoints';
import { ColumnDefinition } from '@/components/_global/BalTable/BalTable.vue';
import TokenPills from '@/components/tables/PoolsTable/TokenPills/TokenPills.vue';

import GaugeVoteInfo from './GaugeVoteInfo.vue';

import { VotingGaugeWithVotes } from '@/services/balancer/gauges/gauge-controller.decorator';
import { Network } from '@balancer-labs/sdk';
import { networkNameFor } from '@/composables/useNetwork';
import useWeb3 from '@/services/web3/useWeb3';
import {
  isStableLike,
  orderedPoolTokens,
  poolURLFor
} from '@/composables/usePool';

/**
 * TYPES
 */
type Props = {
  data?: VotingGaugeWithVotes[];
  isLoading?: boolean;
  noPoolsLabel?: string;
  isPaginated?: boolean;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    data: { type: Array, required: false },
    isLoading: { type: Boolean, required: false },
    noPoolsLabel: { type: String, required: false, default: 'No pools' },
    isPaginated: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  emits: ["clickedVote"] as unknown as undefined,
  setup(__props: {
  data?: VotingGaugeWithVotes[];
  isLoading?: boolean;
  noPoolsLabel?: string;
  isPaginated?: boolean;
}, { emit }: { emit: ({
  (e: 'clickedVote', value: VotingGaugeWithVotes): void;
}), expose: any, slots: any, attrs: any }) {





/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();
const { t } = useI18n();
const { upToLargeBreakpoint } = useBreakpoints();
const { isWalletReady } = useWeb3();

/**
 * DATA
 */
const columns = ref<ColumnDefinition<VotingGaugeWithVotes>[]>([
  {
    name: t('veBAL.liquidityMining.table.chain'),
    id: 'chain',
    accessor: '',
    Header: 'chainColumnHeader',
    Cell: 'networkColumnCell',
    width: 80,
    noGrow: true
  },
  {
    name: t('veBAL.liquidityMining.table.assets'),
    id: 'icons',
    accessor: 'uri',
    Header: 'iconColumnHeader',
    Cell: 'iconColumnCell',
    width: 125,
    noGrow: true
  },
  {
    name: t('veBAL.liquidityMining.table.composition'),
    id: 'poolComposition',
    accessor: 'id',
    Cell: 'poolCompositionCell',
    width: 350
  },
  {
    name: t('veBAL.liquidityMining.table.nextPeriodVotes'),
    accessor: 'id',
    align: 'right',
    id: 'nextPeriodVotes',
    Cell: 'nextPeriodVotesCell',
    sortKey: gauge => Number(gauge.votesNextPeriod),
    width: 150,
    cellClassName: 'font-numeric'
  },
  {
    name: t('veBAL.liquidityMining.table.myVotes'),
    accessor(gauge) {
      const normalizedVotes = scale(new BigNumber(gauge.userVotes), -4);
      return fNum2(normalizedVotes.toString(), {
        style: 'percent',
        maximumFractionDigits: 2
      });
    },
    align: 'right',
    id: 'myVotes',
    sortKey: gauge => Number(gauge.userVotes),
    width: 150,
    cellClassName: 'font-numeric',
    hidden: !isWalletReady.value
  },
  {
    name: t('veBAL.liquidityMining.table.vote'),
    id: 'vote',
    accessor: 'id',
    align: 'right',
    Cell: 'voteColumnCell',
    width: 80,
    hidden: !isWalletReady.value
  }
]);

/**
 * METHODS
 */
function orderedTokenURIs(gauge: VotingGaugeWithVotes): string[] {
  const sortedTokens = orderedPoolTokens(
    gauge.pool.poolType,
    gauge.pool.address,
    gauge.pool.tokens
  );
  return sortedTokens.map(token => gauge.tokenLogoURIs[token?.address || '']);
}

function networkSrc(network: Network) {
  return require(`@/assets/images/icons/networks/${networkNameFor(
    network
  )}.svg`);
}

function redirectToPool(gauge: VotingGaugeWithVotes) {
  window.location.href = poolURLFor(gauge.pool.id, gauge.network);
}

return (_ctx: any,_cache: any) => {
  const _component_NetworkIcon = _resolveComponent("NetworkIcon")!
  const _component_CompositionIcon = _resolveComponent("CompositionIcon")!
  const _component_BalAssetSet = _resolveComponent("BalAssetSet")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalTable = _resolveComponent("BalTable")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    shadow: "lg",
    class: "mt-4",
    square: _unref(upToLargeBreakpoint),
    noBorder: _unref(upToLargeBreakpoint),
    noPad: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BalTable, {
        key: __props.data,
        columns: columns.value,
        data: __props.data,
        "is-loading": __props.isLoading,
        "skeleton-class": "h-64",
        sticky: "both",
        square: _unref(upToLargeBreakpoint),
        "is-paginated": __props.isPaginated,
        "on-row-click": redirectToPool,
        "initial-state": {
        sortColumn: 'poolValue',
        sortDirection: 'desc'
      }
      }, {
        chainColumnHeader: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_NetworkIcon)
          ])
        ]),
        networkColumnCell: _withCtx(({ network }) => [
          (!__props.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("img", {
                    src: networkSrc(network),
                    alt: network,
                    class: "w-6 h-6"
                  }, null, 8, _hoisted_4)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        iconColumnHeader: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_CompositionIcon)
          ])
        ]),
        iconColumnCell: _withCtx((gauge) => [
          (!__props.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_BalAssetSet, {
                  logoURIs: orderedTokenURIs(gauge),
                  width: 100
                }, null, 8, ["logoURIs"])
              ]))
            : _createCommentVNode("", true)
        ]),
        poolCompositionCell: _withCtx(({ pool }) => [
          (!__props.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(TokenPills, {
                  tokens: 
              _unref(orderedPoolTokens)(pool.poolType, pool.address, pool.tokens)
            ,
                  isStablePool: _unref(isStableLike)(pool.poolType)
                }, null, 8, ["tokens", "isStablePool"])
              ]))
            : _createCommentVNode("", true)
        ]),
        nextPeriodVotesCell: _withCtx((gauge) => [
          (!__props.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(GaugeVoteInfo, { gauge: gauge }, null, 8, ["gauge"])
              ]))
            : _createCommentVNode("", true)
        ]),
        voteColumnCell: _withCtx((gauge) => [
          (_unref(isWalletReady))
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_BalBtn, {
                  color: "blue",
                  outline: true,
                  size: "sm",
                  flat: "",
                  block: "",
                  onClick: _withModifiers(($event: any) => (emit('clickedVote', gauge)), ["stop"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('veBAL.liquidityMining.table.vote')), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["columns", "data", "is-loading", "square", "is-paginated"])
    ]),
    _: 1
  }, 8, ["square", "noBorder"]))
}
}

})