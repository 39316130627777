import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId } from "vue"

import { computed, useAttrs } from 'vue';

/**
 * TYPES
 */
type LinkTag = 'a' | 'router-link';

type Props = {
  tag?: LinkTag;
  external?: boolean;
  disabled?: boolean;
  noStyle?: boolean;
};

/**
 * PROPS & EMITS
 */

const __default__ = {
  inheritAttrs: false
};

function setup(__props: {
  tag?: LinkTag;
  external?: boolean;
  disabled?: boolean;
  noStyle?: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const attrs = useAttrs();

/**
 * COMPUTED
 */
const attrs_ = computed(() => {
  let attrs_ = attrs;

  if (props.external) {
    attrs_ = {
      ...attrs_,
      target: '_blank',
      rel: 'noopener noreferrer'
    };
  }

  return attrs_;
});

const classes = computed(() => {
  return {
    link: !props.noStyle,
    'disabled-link': props.disabled
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(__props.tag), _mergeProps({
    class: [_unref(classes)]
  }, _unref(attrs_)), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["class"]))
}
}


export default _defineComponent({
  ...__default__,
  props: {
    tag: { type: String, required: false, default: 'a' },
    external: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    noStyle: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  setup})