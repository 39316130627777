
import BigNumber from 'bignumber.js';
import { PropType, defineComponent, ref, computed } from 'vue';
import { getAddress } from '@ethersproject/address';
import { AddressZero } from '@ethersproject/constants';
import { Pool } from '@balancer-labs/sor/dist/types';
import { SwapV2, SubgraphPoolBase } from '@balancer-labs/sdk';

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { SorReturn } from '@/lib/utils/balancer/helpers/sor/sorManager';
import useWeb3 from '@/services/web3/useWeb3';
import useTokens from '@/composables/useTokens';
import { NATIVE_ASSET_ADDRESS } from '@/constants/tokens';
import { Network } from '@balancer-labs/sdk';

interface Route {
  share: number;
  hops: Hop[];
}

interface Hop {
  pool: {
    id: string;
    tokens: Asset[];
  };
  tokenIn: string;
  tokenOut: string;
  amount: BigNumber;
}

interface Asset {
  address: string;
  share: number;
}

export default defineComponent({
  props: {
    addressIn: {
      type: String,
      required: true
    },
    amountIn: {
      type: String,
      required: true
    },
    addressOut: {
      type: String,
      required: true
    },
    amountOut: {
      type: String,
      required: true
    },
    pools: {
      type: Array as PropType<(Pool | SubgraphPoolBase)[]>,
      required: true
    },
    sorReturn: {
      type: Object as PropType<SorReturn>,
      required: true
    }
  },
  setup(props) {
    const { fNum2 } = useNumbers();

    const { appNetworkConfig } = useWeb3();
    const { tokens } = useTokens();

    const visible = ref(false);

    function toggleVisibility(): void {
      visible.value = !visible.value;
    }

    const input = computed(() => {
      const symbol = tokens.value[props.addressIn].symbol;
      return {
        amount: props.amountIn,
        address: props.addressIn,
        symbol
      };
    });

    const output = computed(() => {
      const symbol = tokens.value[props.addressOut].symbol;
      return {
        amount: props.amountOut,
        address: props.addressOut,
        symbol
      };
    });

    const routes = computed(() => {
      const { sorReturn } = props;

      if (!sorReturn.hasSwaps) {
        return [];
      }

      const pools = props.pools as SubgraphPoolBase[];
      const swaps = sorReturn.result.swaps;
      const addresses = sorReturn.result.tokenAddresses;
      const addressIn = props.addressIn as string;
      const addressOut = props.addressOut as string;

      return getV2Routes(addressIn, addressOut, pools, swaps, addresses);
    });

    function getV2Routes(
      addressIn: string,
      addressOut: string,
      pools: SubgraphPoolBase[],
      swaps: SwapV2[],
      addresses: string[]
    ) {
      const { addresses: constants } = appNetworkConfig;

      addressIn =
        addressIn === NATIVE_ASSET_ADDRESS
          ? constants.weth
          : getAddress(addressIn);
      addressOut =
        addressOut === NATIVE_ASSET_ADDRESS
          ? constants.weth
          : getAddress(addressOut);

      if (
        !pools.length ||
        !swaps.length ||
        !addresses.length ||
        addresses.length === 1
      ) {
        return [];
      }

      // To get total amount we can use all swaps because multihops have a value of 0
      const totalSwapAmount = swaps.reduce((total, rawHops) => {
        return total.plus(rawHops.amount || '0');
      }, new BigNumber(0));

      // Contains direct and multihops
      const routes: Route[] = [];
      // Contains every token > token hop
      const allHops: Hop[] = [];
      for (let i = 0; i < swaps.length; i++) {
        const swap = swaps[i];
        const rawPool = pools.find(pool => pool.id === swap.poolId);

        if (!rawPool) {
          return {};
        }

        const tokenIn =
          addresses[swap.assetInIndex] === AddressZero
            ? constants.weth
            : getAddress(addresses[swap.assetInIndex]);
        const tokenOut =
          addresses[swap.assetOutIndex] === AddressZero
            ? constants.weth
            : getAddress(addresses[swap.assetOutIndex]);

        const isDirectSwap =
          tokenIn === addressIn && tokenOut === addressOut ? true : false;

        const pool = {
          id: rawPool.id,
          tokens: rawPool.tokens
            .map(token => {
              return {
                address: getAddress(token.address),
                share:
                  parseFloat(token.weight || '') || 1 / rawPool.tokens.length
              };
            })
            .sort((a, b) => {
              if (a.address === tokenIn || b.address === tokenOut) {
                return -1;
              }
              if (a.address === tokenOut || b.address === tokenIn) {
                return 1;
              }
              return a.share - b.share;
            })
            .filter((_token, index, tokens) => {
              // Show first 2 and last 2 tokens
              return index < 2 || index > tokens.length - 3;
            })
        };

        const hop = {
          pool,
          tokenIn,
          tokenOut,
          amount: new BigNumber(swap.amount || '0')
        };

        allHops.push(hop);

        if (isDirectSwap) {
          // Direct swaps are pushed to routes array immediately
          const share = hop.amount.div(totalSwapAmount).toNumber();
          const route = {
            share,
            hops: [hop]
          } as Route;
          routes.push(route);
        } else {
          // Only multihops that have a previous partner in sequence are pushed to routes
          if (tokenOut === addressOut && swap.amount === '0') {
            // TokenOut with amount of 0 for multihop means it's a swapExactIn and previous swap is partner of hop
            const swapAmount = new BigNumber(allHops[i - 1].amount);
            const share = swapAmount.div(totalSwapAmount).toNumber();
            const route = {
              share,
              hops: [allHops[i - 1], hop]
            } as Route;
            routes.push(route);
          } else if (tokenIn === addressIn && swap.amount === '0') {
            // TokenIn with amount of 0 for multihop means it's a swapExactOut and previous swap is partner of hop
            const swapAmount = new BigNumber(allHops[i - 1].amount);
            const share = swapAmount.div(totalSwapAmount).toNumber();
            const route = {
              share,
              hops: [hop, allHops[i - 1]]
            } as Route;
            routes.push(route);
          }
        }
      }

      return routes;
    }

    function formatShare(share: number): string {
      return fNum2(share, FNumFormats.percent);
    }

    function getPoolLink(id: string): string {
      const chainId = appNetworkConfig.chainId;
      const prefixMap = {
        [Network.MAINNET]: 'app.',
        [Network.KOVAN]: 'kovan.',
        [Network.POLYGON]: 'polygon.',
        [Network.ARBITRUM]: 'arbitrum.'
      };
      const prefix = prefixMap[chainId] || '';

      return `https://${prefix}balancer.fi/#/pool/${id}`;
    }

    return {
      visible,
      toggleVisibility,

      input,
      output,
      routes,

      formatShare,
      getPoolLink
    };
  }
});
