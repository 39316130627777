import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-6" }
const _hoisted_2 = { class: "pb-4" }
const _hoisted_3 = { class: "rounded-lg border dark:border-gray-800 dark:bg-gray-800 p-3" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "w-full" }
const _hoisted_6 = { class: "flex justify-between" }
const _hoisted_7 = { class: "flex justify-between text-gray-500" }

import useNumbers, { FNumFormats } from '@/composables/useNumbers';

import { FullPool } from '@/services/balancer/subgraph/types';

import { TokenInfo } from '@/types/TokenList';

/**
 * TYPES
 */
type Props = {
  lockablePool: FullPool;
  lockablePoolTokenInfo: TokenInfo;
  totalLpTokens: string;
  fiatTotalLpTokens: string;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    lockablePool: { type: null, required: true },
    lockablePoolTokenInfo: { type: null, required: true },
    totalLpTokens: { type: String, required: true },
    fiatTotalLpTokens: { type: String, required: true }
  } as unknown as undefined,
  setup(__props: {
  lockablePool: FullPool;
  lockablePoolTokenInfo: TokenInfo;
  totalLpTokens: string;
  fiatTotalLpTokens: string;
}) {



/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();

return (_ctx: any,_cache: any) => {
  const _component_BalAsset = _resolveComponent("BalAsset")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('unlockVeBAL.unlockForm.lockedAmount.title')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_BalAsset, {
          address: __props.lockablePool.address,
          class: "mr-2",
          size: 36
        }, null, 8, ["address"]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, _toDisplayString(__props.lockablePoolTokenInfo.symbol), 1),
            _createElementVNode("div", null, _toDisplayString(_unref(fNum2)(__props.totalLpTokens, _unref(FNumFormats).token)), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", null, _toDisplayString(__props.lockablePoolTokenInfo.name), 1),
            _createElementVNode("div", null, _toDisplayString(_unref(fNum2)(__props.fiatTotalLpTokens, _unref(FNumFormats).fiat)), 1)
          ])
        ])
      ])
    ])
  ]))
}
}

})