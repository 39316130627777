import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from 'vue';
import { DecoratedPoolWithShares } from '@/services/balancer/subgraph/types';
import StakePreview, { StakeAction } from './StakePreview.vue';

type Props = {
  isVisible: boolean;
  pool: DecoratedPoolWithShares;
  action: StakeAction;
};


export default _defineComponent({
  props: {
    isVisible: { type: Boolean, required: true },
    pool: { type: null, required: true },
    action: { type: null, required: true }
  } as unknown as undefined,
  emits: ['close', 'success'],
  setup(__props: {
  isVisible: boolean;
  pool: DecoratedPoolWithShares;
  action: StakeAction;
}, { emit }) {




const showFireworks = ref(false);

/**
 * METHODS
 */
function handleClose() {
  showFireworks.value = false;
  emit('close');
}

function handleSuccess() {
  showFireworks.value = true;
  emit('success');
}

return (_ctx: any,_cache: any) => {
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
    _createVNode(_component_BalModal, {
      show: __props.isVisible,
      onClose: handleClose,
      fireworks: showFireworks.value
    }, {
      default: _withCtx(() => [
        _createVNode(StakePreview, {
          pool: __props.pool,
          action: __props.action,
          onClose: handleClose,
          onSuccess: handleSuccess
        }, null, 8, ["pool", "action"])
      ]),
      _: 1
    }, 8, ["show", "fireworks"])
  ]))
}
}

})