
import { defineComponent, computed, toRefs, PropType, Ref } from 'vue';
import { useStore } from 'vuex';
import useNumbers from '@/composables/useNumbers';
import AppSlippageForm from '@/components/forms/AppSlippageForm.vue';
import useFathom from '@/composables/useFathom';

import { ethereumTxTypeOptions } from '@/constants/options';
import useWeb3 from '@/services/web3/useWeb3';

import useEthereumTxType from '@/composables/useEthereumTxType';

export enum TradeSettingsContext {
  trade,
  invest
}

export default defineComponent({
  name: 'TradeSettingsPopover',

  components: {
    AppSlippageForm
  },

  props: {
    context: {
      type: [String, Number] as PropType<TradeSettingsContext>,
      required: true
    },
    isGassless: { type: Boolean, default: false }
  },

  setup(props) {
    // DATA
    const { context }: { context: Ref<TradeSettingsContext> } = toRefs(props);

    // COMPOSABLES
    const store = useStore();
    const { fNum } = useNumbers();
    const { explorerLinks, isEIP1559SupportedNetwork } = useWeb3();
    const { trackGoal, Goals } = useFathom();
    const { ethereumTxType, setEthereumTxType } = useEthereumTxType();

    // COMPUTED
    const appTransactionDeadline = computed<number>(
      () => store.state.app.transactionDeadline
    );

    // METHODS
    const setTransactionDeadline = transactionDeadline =>
      store.commit('app/setTransactionDeadline', transactionDeadline);

    function onActivatorClick(): void {
      if (context.value === TradeSettingsContext.trade) {
        trackGoal(Goals.ClickTradeSettings);
      } else if (context.value === TradeSettingsContext.invest) {
        trackGoal(Goals.ClickInvestSettings);
      }
    }

    return {
      Goals,
      // types,
      TradeSettingsContext,
      // computed
      appTransactionDeadline,
      isEIP1559SupportedNetwork,
      // methods
      setTransactionDeadline,
      fNum,
      explorer: explorerLinks,
      onActivatorClick,
      ethereumTxType,
      setEthereumTxType,
      ethereumTxTypeOptions
    };
  }
});
