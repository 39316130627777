import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, ref } from 'vue';
import useTransactions, {
  TransactionAction
} from '@/composables/useTransactions';
import useEthers from '@/composables/useEthers';
import { useI18n } from 'vue-i18n';
import {
  TransactionReceipt,
  TransactionResponse
} from '@ethersproject/abstract-provider';
import useWeb3 from '@/services/web3/useWeb3';

/**
 * TYPES
 */
type Props = {
  actionFn: () => Promise<TransactionResponse>;
  action: TransactionAction;
  summary: string;
  confirmingLabel: string;
  onConfirmFn?: () => unknown;
};

/**
 * PROPS & EMITS
 */
enum BtnStates {
  Default,
  Init,
  Confirming,
  Confirmed
}

/**
 * STATE
 */

export default _defineComponent({
  props: {
    actionFn: { type: Function, required: true },
    action: { type: null, required: true },
    summary: { type: String, required: true },
    confirmingLabel: { type: String, required: true },
    onConfirmFn: { type: Function, required: false }
  } as unknown as undefined,
  emits: ["init", "confirming", "confirmed", "failed"] as unknown as undefined,
  setup(__props: {
  actionFn: () => Promise<TransactionResponse>;
  action: TransactionAction;
  summary: string;
  confirmingLabel: string;
  onConfirmFn?: () => unknown;
}, { emit }: { emit: ({
  (e: 'init'): void;
  (e: 'confirming', value: TransactionResponse): void;
  (e: 'confirmed', value: TransactionReceipt): void;
  (e: 'failed'): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * COMPOSABLES
 */
const { t } = useI18n();
const { addTransaction } = useTransactions();
const { txListener } = useEthers();
const { isMismatchedNetwork } = useWeb3();

/**
 * TYPES
 */
const btnState = ref(BtnStates.Default);

/**
 * COMPUTED
 */
const isWaitingOnWallet = computed(() => btnState.value === BtnStates.Init);
const isConfirming = computed(() => btnState.value === BtnStates.Confirming);

const loadingLabel = computed(() =>
  isWaitingOnWallet.value ? t('confirm') : props.confirmingLabel
);

/**
 * METHODS
 */
async function initTx() {
  try {
    btnState.value = BtnStates.Init;
    emit('init');

    const tx = await props.actionFn();

    btnState.value = BtnStates.Confirming;
    emit('confirming', tx);

    addTransaction({
      id: tx.hash,
      type: 'tx',
      action: props.action,
      summary: props.summary
    });

    await txListener(tx, {
      onTxConfirmed: async (receipt: TransactionReceipt) => {
        if (props.onConfirmFn) props.onConfirmFn();

        btnState.value = BtnStates.Confirmed;
        emit('confirmed', receipt);
      },
      onTxFailed: () => {
        console.error('Tx failed');
        btnState.value = BtnStates.Default;
        emit('failed');
      }
    });
  } catch (error) {
    btnState.value = BtnStates.Default;
    console.error(error);
  }
}

return (_ctx: any,_cache: any) => {
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createBlock(_component_BalBtn, {
    loadingLabel: _unref(loadingLabel),
    loading: _unref(isWaitingOnWallet) || _unref(isConfirming),
    disabled: _unref(isMismatchedNetwork),
    onClick: _withModifiers(initTx, ["stop"])
  }, null, 8, ["loadingLabel", "loading", "disabled", "onClick"]))
}
}

})