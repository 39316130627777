import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex items-center" }

import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { FullPool } from '@/services/balancer/subgraph/types';
import { VeBalLockInfo } from '@/services/balancer/contracts/contracts/veBAL';

import LockAmount from './components/LockAmount.vue';
import LockSummary from './components/LockSummary.vue';
import LockActions from './components/LockActions.vue';

import { TokenInfo } from '@/types/TokenList';
import { LockType } from '@/components/forms/lock_actions/LockForm/types';
import { expectedVeBal } from '@/composables/useVeBAL';

/**
 * TYPES
 */
type Props = {
  lockablePool: FullPool;
  lockablePoolTokenInfo: TokenInfo;
  lockAmount: string;
  lockEndDate: string;
  lockType: LockType[];
  veBalLockInfo: VeBalLockInfo;
  totalLpTokens: string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    lockablePool: { type: null, required: true },
    lockablePoolTokenInfo: { type: null, required: true },
    lockAmount: { type: String, required: true },
    lockEndDate: { type: String, required: true },
    lockType: { type: Array, required: true },
    veBalLockInfo: { type: null, required: true },
    totalLpTokens: { type: String, required: true }
  } as unknown as undefined,
  emits: ["close"] as unknown as undefined,
  setup(__props: {
  lockablePool: FullPool;
  lockablePoolTokenInfo: TokenInfo;
  lockAmount: string;
  lockEndDate: string;
  lockType: LockType[];
  veBalLockInfo: VeBalLockInfo;
  totalLpTokens: string;
}, { emit }: { emit: ({
  (e: 'close'): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * STATE
 */
const lockConfirmed = ref(false);

// This value should be static when modal is opened.
const expectedVeBalAmount = expectedVeBal(
  props.totalLpTokens,
  props.lockEndDate
);

/**
 * COMPOSABLES
 */
const { t } = useI18n();

/**
 * COMPUTED
 */
const title = computed(() => {
  if (props.lockType.length === 1) {
    return lockConfirmed.value
      ? t(`getVeBAL.previewModal.titles.${props.lockType[0]}.confirmed`)
      : t(`getVeBAL.previewModal.titles.${props.lockType[0]}.default`);
  }
  return lockConfirmed.value
    ? t(`getVeBAL.previewModal.titles.${LockType.CREATE_LOCK}.confirmed`)
    : t(`getVeBAL.previewModal.titles.${LockType.CREATE_LOCK}.default`);
});

/**
 * METHODS
 */
function handleClose() {
  emit('close');
}

function handleSuccess() {
  lockConfirmed.value = true;
}

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalCircle = _resolveComponent("BalCircle")!
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createBlock(_component_BalModal, {
    show: "",
    fireworks: lockConfirmed.value,
    onClose: handleClose
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (lockConfirmed.value)
          ? (_openBlock(), _createBlock(_component_BalCircle, {
              key: 0,
              size: "8",
              color: "green",
              class: "text-white mr-2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BalIcon, { name: "check" })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("h4", null, _toDisplayString(_unref(title)), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(LockAmount, {
        lockablePool: __props.lockablePool,
        totalLpTokens: __props.totalLpTokens
      }, null, 8, ["lockablePool", "totalLpTokens"]),
      _createVNode(LockSummary, {
        lockablePool: __props.lockablePool,
        totalLpTokens: __props.totalLpTokens,
        lockAmount: __props.lockAmount,
        lockEndDate: __props.lockEndDate,
        expectedVeBalAmount: _unref(expectedVeBalAmount),
        lockType: __props.lockType,
        veBalLockInfo: __props.veBalLockInfo
      }, null, 8, ["lockablePool", "totalLpTokens", "lockAmount", "lockEndDate", "expectedVeBalAmount", "lockType", "veBalLockInfo"]),
      _createVNode(LockActions, {
        lockablePool: __props.lockablePool,
        lockAmount: __props.lockAmount,
        lockEndDate: __props.lockEndDate,
        lockType: __props.lockType,
        lockablePoolTokenInfo: __props.lockablePoolTokenInfo,
        onSuccess: handleSuccess,
        class: "mt-4"
      }, null, 8, ["lockablePool", "lockAmount", "lockEndDate", "lockType", "lockablePoolTokenInfo"])
    ]),
    _: 1
  }, 8, ["fireworks"]))
}
}

})