import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Teleport as _Teleport, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "text-xs text-gray-500 leading-none" }
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = { class: "mt-6" }

import { computed, ref, toRef } from 'vue';

import { bnum } from '@/lib/utils';

import { FullPool } from '@/services/balancer/subgraph/types';
import { configService } from '@/services/config/config.service';

import LockPreviewModal from '../LockPreviewModal/LockPreviewModal.vue';

import { VeBalLockInfo } from '@/services/balancer/contracts/contracts/veBAL';

import useTokens from '@/composables/useTokens';

import { TokenInfo } from '@/types/TokenList';

import Summary from './components/Summary.vue';
import LockAmount from './components/LockAmount.vue';
import LockEndDate from './components/LockEndDate.vue';

import useLockState from '../../composables/useLockState';
import useLockAmount from '../../composables/useLockAmount';
import useLockEndDate from '../../composables/useLockEndDate';

import { LockType } from '@/components/forms/lock_actions/LockForm/types';

import useWeb3 from '@/services/web3/useWeb3';
import { expectedVeBal } from '@/composables/useVeBAL';
import useVeBalLockInfoQuery from '@/composables/queries/useVeBalLockInfoQuery';

/**
 * TYPES
 */
type Props = {
  lockablePool: FullPool;
  lockablePoolTokenInfo: TokenInfo;
  veBalLockInfo?: VeBalLockInfo;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    lockablePool: { type: null, required: true },
    lockablePoolTokenInfo: { type: null, required: true },
    veBalLockInfo: { type: null, required: false }
  } as unknown as undefined,
  setup(__props: {
  lockablePool: FullPool;
  lockablePoolTokenInfo: TokenInfo;
  veBalLockInfo?: VeBalLockInfo;
}) {

const props = __props


/**
 * STATE
 */
const showPreviewModal = ref(false);

const { lockEndDate, lockAmount } = useLockState();
const {
  isWalletReady,
  toggleWalletSelectModal,
  isMismatchedNetwork
} = useWeb3();

const {
  isValidLockAmount,
  isIncreasedLockAmount,
  totalLpTokens
} = useLockAmount(toRef(props, 'veBalLockInfo'));

const {
  minLockEndDateTimestamp,
  maxLockEndDateTimestamp,
  isValidLockEndDate,
  isExtendedLockEndDate
} = useLockEndDate(props.veBalLockInfo);
const { refetch: refetchLockInfo } = useVeBalLockInfoQuery();

/**
 * COMPOSABLES
 */
const { balanceFor } = useTokens();

/**
 * COMPUTED
 */
const lockablePoolBptBalance = computed(() =>
  balanceFor(props.lockablePool.address)
);

const submissionDisabled = computed(() => {
  if (isMismatchedNetwork.value) {
    return true;
  }

  if (props.veBalLockInfo?.hasExistingLock) {
    return !isIncreasedLockAmount.value && !isExtendedLockEndDate.value;
  }

  return (
    !bnum(lockablePoolBptBalance.value).gt(0) ||
    !isValidLockAmount.value ||
    !isValidLockEndDate.value
  );
});

const expectedVeBalAmount = computed(() => {
  if (submissionDisabled.value) {
    return '0';
  }

  return expectedVeBal(totalLpTokens.value, lockEndDate.value);
});

const lockType = computed(() => {
  if (props.veBalLockInfo?.hasExistingLock) {
    if (isIncreasedLockAmount.value && isExtendedLockEndDate.value) {
      return [LockType.INCREASE_LOCK, LockType.EXTEND_LOCK];
    }
    if (isExtendedLockEndDate.value) {
      return [LockType.EXTEND_LOCK];
    }
    if (isIncreasedLockAmount.value) {
      return [LockType.INCREASE_LOCK];
    }
  }
  return [LockType.CREATE_LOCK];
});

/**
 * METHODS
 */
function handleClosePreviewModal() {
  showPreviewModal.value = false;
  refetchLockInfo.value();
}

return (_ctx: any,_cache: any) => {
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BalCard, {
      shadow: "xl",
      exposeOverflow: "",
      noBorder: ""
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(configService).network.chainName), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('getVeBAL.lockForm.title')), 1)
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(LockAmount, {
          lockablePool: __props.lockablePool,
          lockablePoolTokenInfo: __props.lockablePoolTokenInfo
        }, null, 8, ["lockablePool", "lockablePoolTokenInfo"]),
        _createVNode(LockEndDate, {
          minLockEndDateTimestamp: _unref(minLockEndDateTimestamp),
          maxLockEndDateTimestamp: _unref(maxLockEndDateTimestamp),
          veBalLockInfo: __props.veBalLockInfo
        }, null, 8, ["minLockEndDateTimestamp", "maxLockEndDateTimestamp", "veBalLockInfo"]),
        _createVNode(Summary, { expectedVeBalAmount: _unref(expectedVeBalAmount) }, null, 8, ["expectedVeBalAmount"]),
        _createElementVNode("div", _hoisted_4, [
          (!_unref(isWalletReady))
            ? (_openBlock(), _createBlock(_component_BalBtn, {
                key: 0,
                label: _ctx.$t('connectWallet'),
                color: "gradient",
                block: "",
                onClick: _unref(toggleWalletSelectModal)
              }, null, 8, ["label", "onClick"]))
            : (_openBlock(), _createBlock(_component_BalBtn, {
                key: 1,
                color: "gradient",
                block: "",
                disabled: _unref(submissionDisabled),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (showPreviewModal.value = true))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('preview')), 1)
                ]),
                _: 1
              }, 8, ["disabled"]))
        ])
      ]),
      _: 1
    }),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      (showPreviewModal.value)
        ? (_openBlock(), _createBlock(LockPreviewModal, {
            key: 0,
            lockablePool: __props.lockablePool,
            lockablePoolTokenInfo: __props.lockablePoolTokenInfo,
            lockAmount: _unref(lockAmount),
            lockEndDate: _unref(lockEndDate),
            lockType: _unref(lockType),
            veBalLockInfo: __props.veBalLockInfo,
            totalLpTokens: _unref(totalLpTokens),
            onClose: handleClosePreviewModal
          }, null, 8, ["lockablePool", "lockablePoolTokenInfo", "lockAmount", "lockEndDate", "lockType", "veBalLockInfo", "totalLpTokens"]))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}
}

})