import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "px-2 lg:px-0" }
const _hoisted_2 = { class: "lg:container lg:mx-auto py-12" }
const _hoisted_3 = { class: "grid grid-cols-1 lg:grid-cols-2 gap-12" }
const _hoisted_4 = { class: "mb-4" }
const _hoisted_5 = { class: "bg-gray-200 dark:bg-gray-700 p-4 rounded-lg" }
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = { class: "lg:container lg:mx-auto py-12" }
const _hoisted_8 = { class: "font-body font-bold text-2xl" }
const _hoisted_9 = {
  key: 1,
  class: "flex items-center mt-6 mb-2"
}
const _hoisted_10 = { class: "text-xl ml-2" }
const _hoisted_11 = { class: "text-xl mt-8" }
const _hoisted_12 = { class: "flex mt-4" }
const _hoisted_13 = { class: "text-base mb-2" }
const _hoisted_14 = { class: "font-body font-bold text-2xl mt-8" }
const _hoisted_15 = ["src", "alt"]

import { computed, watch } from 'vue';
import { configService } from '@/services/config/config.service';
import { Gauge } from '@/services/balancer/gauges/types';
import useTokens from '@/composables/useTokens';
import { formatUnits } from 'ethers/lib/utils';
import useNumbers from '@/composables/useNumbers';
import { getAddress } from '@ethersproject/address';
import { isStableLike } from '@/composables/usePool';
import { useTokenHelpers } from '@/composables/useTokenHelpers';
import { bnum } from '@/lib/utils';
import useWeb3 from '@/services/web3/useWeb3';
import useApp from '@/composables/useApp';
import { GaugePool, useClaimsData } from '@/composables/useClaimsData';

import { RewardRow } from '@/components/tables/BalClaimsTable/BalClaimsTable.vue';
import BalClaimsTable from '@/components/tables/BalClaimsTable/BalClaimsTable.vue';
import LegacyClaims from '@/components/contextual/pages/claim/LegacyClaims.vue';
import GaugeRewardsTable from '@/components/tables/GaugeRewardsTable/GaugeRewardsTable.vue';
import { isKovan, isL2, isMainnet } from '@/composables/useNetwork';

/**
 * TYPES
 */
type GaugeTable = {
  gauge: Gauge;
  pool: GaugePool;
};

/**
 * COMPOSABLES
 */

export default _defineComponent({
  setup(__props) {

/**
 * Claim Page
 */
const { injectTokens, getToken } = useTokens();
const { balToken } = useTokenHelpers();
const { toFiat, fNum2 } = useNumbers();
const { isWalletReady } = useWeb3();
const { appLoading } = useApp();
const { gauges, gaugePools, queriesLoading } = useClaimsData();

/**
 * STATE
 */
const networks = [
  {
    id: 'ethereum',
    name: 'Ethereum',
    subdomain: 'app',
    key: '1'
  },
  {
    id: 'polygon',
    name: 'Polygon',
    subdomain: 'polygon',
    key: '137'
  },
  {
    id: 'arbitrum',
    name: 'Arbitrum',
    subdomain: 'arbitrum',
    key: '42161'
  }
];

/**
 * COMPUTED
 */
const networkBtns = computed(() => {
  return networks.filter(network => network.key !== configService.network.key);
});

const supportsContractBasedClaiming = computed(
  (): boolean => isMainnet.value || isKovan.value
);

const balRewardsData = computed((): RewardRow[] => {
  if (!isWalletReady.value || appLoading.value) return [];
  // Using reduce to filter out gauges we don't have corresponding pools for
  return gauges.value.reduce<RewardRow[]>((arr, gauge) => {
    const amount = formatUnits(gauge.claimableTokens, balToken.value.decimals);
    const pool = gaugePools.value.find(pool => pool.id === gauge.poolId);

    if (pool && bnum(amount).gt(0))
      arr.push({
        gauge,
        pool,
        amount,
        value: toFiat(amount, balToken.value.address)
      });

    return arr;
  }, []);
});

const gaugesWithRewards = computed((): Gauge[] => {
  return gauges.value.filter(gauge => gauge.rewardTokens.length > 0);
});

const gaugeTables = computed((): GaugeTable[] => {
  // Only return gauges if we have a corresponding pool and rewards > 0
  return gaugesWithRewards.value.reduce<GaugeTable[]>((arr, gauge) => {
    const pool = gaugePools.value.find(pool => pool.id === gauge.poolId);
    const totalRewardValue = Object.values(gauge.claimableRewards).reduce(
      (acc, reward) => acc.plus(reward),
      bnum(0)
    );

    if (pool && totalRewardValue.gt(0))
      arr.push({
        gauge,
        pool
      });

    return arr;
  }, []);
});

/**
 * METHODS
 */
async function injectRewardTokens(gauges: Gauge[]): Promise<void> {
  const allRewardTokens = gauges.map(gauge => gauge.rewardTokens).flat();
  return await injectTokens(allRewardTokens);
}

async function injectPoolTokens(pools: GaugePool[]): Promise<void> {
  const allPoolTokens = pools.map(pools => pools.tokensList).flat();
  return await injectTokens(allPoolTokens);
}

function gaugeTitle(pool: GaugePool): string {
  const _tokens = pool.tokens.map(token => ({
    ...token,
    ...getToken(getAddress(token.address))
  }));

  if (isStableLike(pool.poolType)) {
    return Object.values(_tokens)
      .map(token => token.symbol)
      .join(' / ');
  }

  return Object.values(_tokens)
    .map(
      token =>
        `${fNum2(token.weight, {
          style: 'percent',
          maximumFractionDigits: 0
        })} ${token.symbol}`
    )
    .join(' / ');
}

/**
 * WATCHERS
 */
watch(gauges, async newGauges => {
  if (newGauges) await injectRewardTokens(newGauges);
});

watch(gaugePools, async newPools => {
  if (newPools) await injectPoolTokens(newPools);
});

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalFlexGrid = _resolveComponent("BalFlexGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('pages.claim.title')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('pages.claim.description')), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('pages.claim.transitionInfo.title')), 1),
          _createElementVNode("p", null, _toDisplayString(_unref(isL2)
                ? _ctx.$t('pages.claim.transitionInfo.descriptionL2')
                : _ctx.$t('pages.claim.transitionInfo.description')), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_unref(supportsContractBasedClaiming))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("h2", _hoisted_8, _toDisplayString(_unref(configService).network.chainName) + " " + _toDisplayString(_ctx.$t('liquidityIncentives')), 1),
            (_unref(appLoading))
              ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
                  key: 0,
                  class: "mt-6 mb-2 h-8 w-64"
                }))
              : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_BalAsset, {
                    address: _unref(balToken)?.address
                  }, null, 8, ["address"]),
                  _createElementVNode("h3", _hoisted_10, " Balancer (BAL) " + _toDisplayString(_ctx.$t('earnings').toLowerCase()), 1)
                ])),
            _createVNode(BalClaimsTable, {
              rewardsData: _unref(balRewardsData),
              isLoading: _unref(queriesLoading) || _unref(appLoading)
            }, null, 8, ["rewardsData", "isLoading"]),
            (!_unref(queriesLoading) && !_unref(appLoading) && _unref(gaugesWithRewards).length > 0)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.$t('otherTokenEarnings')), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(gaugeTables), ({ gauge, pool }) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: gauge.id
                    }, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("h4", _hoisted_13, _toDisplayString(gaugeTitle(pool)), 1)
                      ]),
                      _createVNode(GaugeRewardsTable, {
                        gauge: gauge,
                        isLoading: _unref(queriesLoading) || _unref(appLoading)
                      }, null, 8, ["gauge", "isLoading"])
                    ]))
                  }), 128))
                ], 64))
              : _createCommentVNode("", true),
            _createElementVNode("h2", _hoisted_14, _toDisplayString(_ctx.$t('pages.claim.titles.incentivesOnOtherNetworks')), 1),
            _createVNode(_component_BalFlexGrid, {
              class: "mt-4",
              flexWrap: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(networkBtns), (network) => {
                  return (_openBlock(), _createBlock(_component_BalBtn, {
                    tag: "a",
                    key: network.id,
                    href: `https://${network.subdomain}.balancer.fi/#/claim`,
                    color: "white"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src: require(`@/assets/images/icons/networks/${network.id}.svg`),
                        alt: network.id,
                        class: "w-6 h-6 rounded-full shadow-sm mr-2"
                      }, null, 8, _hoisted_15),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('pages.claim.btns.claimOn')) + " " + _toDisplayString(network.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["href"]))
                }), 128))
              ]),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true),
      (_unref(isWalletReady))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("h2", {
              class: _normalizeClass([
            'font-body font-bold text-2xl',
            { 'mt-8': _unref(supportsContractBasedClaiming) }
          ])
            }, _toDisplayString(!_unref(supportsContractBasedClaiming)
              ? `${_unref(configService).network.chainName} ${_ctx.$t(
                  'liquidityIncentives'
                )}`
              : _ctx.$t('pages.claim.titles.legacyIncentives')), 3),
            _createVNode(LegacyClaims)
          ], 64))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})