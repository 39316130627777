import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { FullPool } from '@/services/balancer/subgraph/types';

import Pool from './Pool';
import BoostedPool from './BoostedPool';

/**
 * TYPES
 */
type Props = {
  pool: FullPool;
  loading: boolean;
};

/**
 * PROPS
 */


export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    loading: { type: Boolean, required: true, default: false }
  } as unknown as undefined,
  setup(__props: {
  pool: FullPool;
  loading: boolean;
}) {

const props = __props


return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!

  return (props.loading)
    ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
        key: 0,
        class: "h-64"
      }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (props.pool.onchain?.linearPools)
          ? (_openBlock(), _createBlock(_unref(BoostedPool), {
              key: 0,
              pool: props.pool
            }, null, 8, ["pool"]))
          : (_openBlock(), _createBlock(_unref(Pool), {
              key: 1,
              pool: props.pool
            }, null, 8, ["pool"]))
      ], 64))
}
}

})