import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pb-16" }

import { computed } from 'vue';
import { useRoute } from 'vue-router';
import AppNav from '@/components/navs/AppNav/AppNav.vue';
import AppHero from '@/components/heros/AppHero.vue';
import useBreakpoints from '@/composables/useBreakpoints';
import StakingProvider from '@/providers/local/staking.provider';

/**
 * COMPOSABLES
 */

export default _defineComponent({
  setup(__props) {

const route = useRoute();
const { isDesktop } = useBreakpoints();

/**
 * COMPUTED
 */
const isHomePage = computed(() => route.path === '/');

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(AppNav),
    (_unref(isHomePage))
      ? (_openBlock(), _createBlock(_unref(StakingProvider), { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(AppHero)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view, {
        key: _ctx.$route.path
      })
    ]),
    (_unref(isDesktop))
      ? (_openBlock(), _createBlock(_component_BalBtn, {
          key: 1,
          id: "intercom-activator",
          circle: "",
          size: "lg",
          color: "blue",
          class: "fixed bottom-0 right-0 m-4 z-100"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BalIcon, {
              name: "message-square",
              size: "lg"
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})