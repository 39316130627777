import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-8" }

import { computed, ref } from 'vue';

import useStaking from '@/composables/staking/useStaking';

import { FullPool } from '@/services/balancer/subgraph/types';

import PoolsTable from '@/components/tables/PoolsTable/PoolsTable.vue';
import StakePreviewModal from '../../stake/StakePreviewModal.vue';

/** STATE */

export default _defineComponent({
  setup(__props) {

const showStakeModal = ref(false);
const stakePool = ref<FullPool | undefined>();

/** COMPOSABLES */
const {
  stakedPools,
  isLoadingStakingData,
  isLoadingStakedPools,
  setPoolAddress,
  isLoadingUserPools,
  isUserPoolsIdle
} = useStaking();

/** COMPUTED */
const isLoading = computed(() => {
  return (
    isLoadingStakingData.value ||
    isLoadingStakedPools.value ||
    isLoadingUserPools.value ||
    isUserPoolsIdle.value
  );
});

/** METHODS */
function handleStake(pool: FullPool) {
  setPoolAddress(pool.address);
  showStakeModal.value = true;
  stakePool.value = pool;
}

function handleModalClose() {
  showStakeModal.value = false;
}

return (_ctx: any,_cache: any) => {
  const _component_BalStack = _resolveComponent("BalStack")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BalStack, {
        vertical: "",
        spacing: "sm"
      }, {
        default: _withCtx(() => [
          _createElementVNode("h5", null, _toDisplayString(_ctx.$t('staking.stakedPools')), 1),
          _createVNode(PoolsTable, {
            key: _unref(stakedPools),
            data: _unref(stakedPools),
            noPoolsLabel: _ctx.$t('noInvestments'),
            hiddenColumns: ['poolVolume', 'poolValue', 'migrate', 'stake'],
            onTriggerStake: handleStake,
            isLoading: _unref(isLoading),
            showPoolShares: ""
          }, null, 8, ["data", "noPoolsLabel", "isLoading"])
        ]),
        _: 1
      })
    ]),
    _createVNode(StakePreviewModal, {
      pool: stakePool.value,
      isVisible: showStakeModal.value,
      onClose: handleModalClose,
      action: "stake"
    }, null, 8, ["pool", "isVisible"])
  ], 64))
}
}

})