import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { faucet } from '@/services/balancer/contracts/contracts/faucet';
import TxActionBtn from '../TxActionBtn/TxActionBtn.vue';

/**
 * TYPES
 */
type Props = {
  token: string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    token: { type: String, required: true }
  } as unknown as undefined,
  setup(__props: {
  token: string;
}) {

const props = __props


/**
 * STATE
 */
const tokenAddress = computed((): string => props.token);

/**
 * METHODS
 */
async function dripTx() {
  return await faucet.drip(tokenAddress.value);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TxActionBtn, {
    label: "Drip",
    color: "gradient",
    size: "sm",
    actionFn: dripTx,
    action: "drip",
    summary: "Dripping from faucet",
    confirmingLabel: "Drip"
  }))
}
}

})