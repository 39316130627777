import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid gap-2 grid-rows-1 grid-flow-col" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]

import { computed } from 'vue';
import useBreakpoints from '@/composables/useBreakpoints';
import AppNavAccountBtn from './AppNavAccountBtn.vue';
import useWeb3 from '@/services/web3/useWeb3';
import AppNavActivityBtn from './AppNavActivityBtn/AppNavActivityBtn.vue';
import DarkModeToggle from '@/components/btns/DarkModeToggle.vue';
import AppNavNetworkSelect from './AppNavNetworkSelect.vue';
import { useSidebar } from '@/composables/useSidebar';

/**
 * COMPOSABLES
 */

export default _defineComponent({
  setup(__props) {

const { isMobile, isDesktop } = useBreakpoints();
const { account, connector, toggleWalletSelectModal } = useWeb3();
const { setSidebarOpen } = useSidebar();

/**
 * COMPUTED
 */
const hideNetworkSelect = computed(() => connector.value?.id === 'gnosis');

return (_ctx: any,_cache: any) => {
  const _component_WalletIcon = _resolveComponent("WalletIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalIcon = _resolveComponent("BalIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(isDesktop))
      ? (_openBlock(), _createBlock(DarkModeToggle, { key: 0 }))
      : _createCommentVNode("", true),
    (_unref(account))
      ? (_openBlock(), _createBlock(AppNavActivityBtn, { key: 1 }))
      : _createCommentVNode("", true),
    (_unref(account))
      ? (_openBlock(), _createBlock(AppNavAccountBtn, { key: 2 }))
      : (_openBlock(), _createBlock(_component_BalBtn, {
          key: 3,
          color: "white",
          size: _unref(isMobile) ? 'md' : 'sm',
          onClick: _unref(toggleWalletSelectModal)
        }, {
          default: _withCtx(() => [
            _createVNode(_component_WalletIcon, { class: "mr-2" }),
            _createElementVNode("span", {
              class: "hidden lg:inline-block",
              textContent: _toDisplayString(_ctx.$t('connectWallet'))
            }, null, 8, _hoisted_2),
            _createElementVNode("span", {
              class: "lg:hidden",
              textContent: _toDisplayString(_ctx.$t('connect'))
            }, null, 8, _hoisted_3)
          ]),
          _: 1
        }, 8, ["size", "onClick"])),
    (!_unref(hideNetworkSelect))
      ? (_openBlock(), _createBlock(AppNavNetworkSelect, { key: 4 }))
      : _createCommentVNode("", true),
    (_unref(isMobile))
      ? (_openBlock(), _createBlock(_component_BalBtn, {
          key: 5,
          color: "white",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(setSidebarOpen)(true))),
          flat: "",
          circle: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BalIcon, {
              name: "menu",
              size: "lg"
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})