import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Teleport as _Teleport, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "text-xs text-gray-500 leading-none" }
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = { class: "mt-6" }

import { computed, ref } from 'vue';

import { bnum } from '@/lib/utils';

import { FullPool } from '@/services/balancer/subgraph/types';
import { configService } from '@/services/config/config.service';
import useWeb3 from '@/services/web3/useWeb3';
import { VeBalLockInfo } from '@/services/balancer/contracts/contracts/veBAL';

import UnlockPreviewModal from '../UnlockPreviewModal/UnlockPreviewModal.vue';

import { TokenInfo } from '@/types/TokenList';

import LockedAmount from './components/LockedAmount.vue';

/**
 * TYPES
 */
type Props = {
  lockablePool: FullPool;
  lockablePoolTokenInfo: TokenInfo;
  veBalLockInfo?: VeBalLockInfo;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    lockablePool: { type: null, required: true },
    lockablePoolTokenInfo: { type: null, required: true },
    veBalLockInfo: { type: null, required: false }
  } as unknown as undefined,
  setup(__props: {
  lockablePool: FullPool;
  lockablePoolTokenInfo: TokenInfo;
  veBalLockInfo?: VeBalLockInfo;
}) {

const props = __props


/**
 * STATE
 */
const showPreviewModal = ref(false);

/**
 * COMPOSABLES
 */
const {
  isWalletReady,
  toggleWalletSelectModal,
  isMismatchedNetwork
} = useWeb3();

/**
 * COMPUTED
 */
const totalLpTokens = computed(() =>
  props.veBalLockInfo?.isExpired ? props.veBalLockInfo.lockedAmount : '0'
);

const fiatTotalLpTokens = computed(() =>
  bnum(props.lockablePool.totalLiquidity)
    .div(props.lockablePool.totalShares)
    .times(totalLpTokens.value)
);

const submissionDisabled = computed(() => {
  if (isMismatchedNetwork.value) {
    return true;
  }

  return bnum(totalLpTokens.value).isZero();
});

return (_ctx: any,_cache: any) => {
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BalCard, {
      shadow: "xl",
      exposeOverflow: "",
      noBorder: ""
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(configService).network.chainName), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('unlockVeBAL.unlockForm.title')), 1)
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(LockedAmount, {
          lockablePool: __props.lockablePool,
          lockablePoolTokenInfo: __props.lockablePoolTokenInfo,
          totalLpTokens: _unref(totalLpTokens),
          fiatTotalLpTokens: _unref(fiatTotalLpTokens)
        }, null, 8, ["lockablePool", "lockablePoolTokenInfo", "totalLpTokens", "fiatTotalLpTokens"]),
        _createElementVNode("div", _hoisted_4, [
          (!_unref(isWalletReady))
            ? (_openBlock(), _createBlock(_component_BalBtn, {
                key: 0,
                label: _ctx.$t('connectWallet'),
                color: "gradient",
                block: "",
                onClick: _unref(toggleWalletSelectModal)
              }, null, 8, ["label", "onClick"]))
            : (_openBlock(), _createBlock(_component_BalBtn, {
                key: 1,
                color: "gradient",
                block: "",
                disabled: _unref(submissionDisabled),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (showPreviewModal.value = true))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('preview')), 1)
                ]),
                _: 1
              }, 8, ["disabled"]))
        ])
      ]),
      _: 1
    }),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      (showPreviewModal.value)
        ? (_openBlock(), _createBlock(UnlockPreviewModal, {
            key: 0,
            lockablePool: __props.lockablePool,
            lockablePoolTokenInfo: __props.lockablePoolTokenInfo,
            veBalLockInfo: __props.veBalLockInfo,
            totalLpTokens: _unref(totalLpTokens),
            fiatTotalLpTokens: _unref(fiatTotalLpTokens),
            onClose: _cache[1] || (_cache[1] = ($event: any) => (showPreviewModal.value = false))
          }, null, 8, ["lockablePool", "lockablePoolTokenInfo", "veBalLockInfo", "totalLpTokens", "fiatTotalLpTokens"]))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}
}

})