
import useBreakpoints from '@/composables/useBreakpoints';
import ConfigService from '@/services/config/config.service';
import { defineComponent } from 'vue';

export interface NetworkOption {
  id: string;
  name: string;
  subdomain?: string;
  key?: string;
}

export default defineComponent({
  name: 'AppNavNetworkSelect',

  setup() {
    // SERVICES
    const configService = new ConfigService();

    // COMPOSABLES
    const { upToLargeBreakpoint } = useBreakpoints();

    // DATA
    const networks = [
      {
        id: 'ethereum',
        name: 'Ethereum',
        subdomain: 'app',
        key: '1'
      },
      {
        id: 'polygon',
        name: 'Polygon',
        subdomain: 'polygon',
        key: '137'
      },
      {
        id: 'arbitrum',
        name: 'Arbitrum',
        subdomain: 'arbitrum',
        key: '42161'
      }
    ];

    const appNetworkSupported = networks
      .map(network => network.key)
      .includes(configService.network.key);

    const activeNetwork = networks.find(network => {
      if (!appNetworkSupported && network.id === 'ethereum') return true;
      return isActive(network);
    });

    // METHODS
    function iconSrc(network: NetworkOption): string {
      return require(`@/assets/images/icons/networks/${network.id}.svg`);
    }

    function appUrl(network: NetworkOption): string {
      return `https://${network.subdomain}.balancer.fi`;
    }

    function isActive(network: NetworkOption): boolean {
      if (!appNetworkSupported && network.id === 'ethereum') return true;
      return configService.network.key === network.key;
    }

    return {
      // computed
      upToLargeBreakpoint,
      // data
      networks,
      activeNetwork,
      // methods
      isActive,
      appUrl,
      iconSrc
    };
  }
});
