import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, unref as _unref, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-319aa9bd")
const _hoisted_1 = { class: "sidebar-overlay" }
const _hoisted_2 = {
  key: 0,
  class: "app-sidebar"
}
_popScopeId()

import { ref } from 'vue';
import { useSidebar } from '@/composables/useSidebar';
import SidebarContent from './SidebarContent.vue';

/**
 * STATE
 */

export default _defineComponent({
  setup(__props) {

const showSidebar = ref(false);

/**
 * COMPOSABLES
 */
const { setSidebarOpen } = useSidebar();

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "overlay",
    onAfterEnter: _cache[3] || (_cache[3] = ($event: any) => (showSidebar.value = true)),
    appear: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_Transition, {
          name: "sidebar",
          onAfterLeave: _cache[1] || (_cache[1] = ($event: any) => (_unref(setSidebarOpen)(false)))
        }, {
          default: _withCtx(() => [
            (showSidebar.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(SidebarContent, {
                    onClose: _cache[0] || (_cache[0] = ($event: any) => (showSidebar.value = false))
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_BalIcon, {
          name: "x",
          size: "lg",
          class: "fixed top-8 right-8 text-white cursor-pointer",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (showSidebar.value = false))
        })
      ])
    ]),
    _: 1
  }))
}
}

})