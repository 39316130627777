import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }

import { computed } from 'vue';
import AppIcon from './AppIcon.vue';

/**
 * TYPES
 */
type Props = {
  forceDark?: boolean;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    forceDark: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  setup(__props: {
  forceDark?: boolean;
}) {

const props = __props


/**
 * COMPUTED
 */
const textColor = computed(() =>
  props.forceDark ? 'text-white' : 'text-black dark:text-white'
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AppIcon, { forceDark: __props.forceDark }, null, 8, ["forceDark"]),
    _createElementVNode("span", {
      class: _normalizeClass(['mr-1 font-secondary text-xl font-semibold', _unref(textColor)])
    }, " Balancer ", 2)
  ]))
}
}

})