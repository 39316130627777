import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "px-6 py-4 flex items-center" }
const _hoisted_2 = { class: "ml-2" }
const _hoisted_3 = { class: "flex justify-end" }
const _hoisted_4 = { class: "" }

import { ref, computed } from 'vue';
import { ColumnDefinition } from '@/components/_global/BalTable/BalTable.vue';
import useBreakpoints from '@/composables/useBreakpoints';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { Gauge } from '@/services/balancer/gauges/types';
import { TokenInfo } from '@/types/TokenList';
import useTokens from '@/composables/useTokens';
import { formatUnits } from 'ethers/lib/utils';
import { bnum } from '@/lib/utils';
import ClaimRewardsBtn from '@/components/btns/ClaimRewardsBtn/ClaimRewardsBtn.vue';
import { useI18n } from 'vue-i18n';

/**
 * TYPES
 */
type Props = {
  gauge: Gauge;
  isLoading: boolean;
};

type Reward = {
  token: TokenInfo;
  amount: string;
  value: string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    gauge: { type: null, required: true },
    isLoading: { type: Boolean, required: true }
  } as unknown as undefined,
  setup(__props: {
  gauge: Gauge;
  isLoading: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { t } = useI18n();
const { upToLargeBreakpoint } = useBreakpoints();
const { fNum2, toFiat } = useNumbers();
const { getToken } = useTokens();

/**
 * STATE
 */
const columns = ref<ColumnDefinition<Reward>[]>([
  {
    name: t('incentiveToken'),
    id: 'token',
    accessor: 'token',
    Cell: 'tokenColumnCell',
    width: 475,
    noGrow: true
  },
  {
    name: t('amount'),
    id: 'amount',
    align: 'right',
    width: 150,
    accessor: ({ amount, token }) =>
      `${fNum2(amount, FNumFormats.token)} ${token.symbol}`
  },
  {
    name: t('value'),
    id: 'value',
    align: 'right',
    width: 150,
    totalsCell: 'totalValueCell',
    accessor: ({ value }) => fNum2(value, FNumFormats.fiat)
  },
  {
    name: '',
    id: 'claim',
    accessor: 'claim',
    Cell: 'claimColumnCell',
    totalsCell: 'totalClaimCell',
    width: 150
  }
]);

/**
 * COMPUTED
 */
const rewardsData = computed((): Reward[] => {
  return props.gauge.rewardTokens.map(tokenAddress => {
    const token = getToken(tokenAddress);
    const amount = formatUnits(
      props.gauge.claimableRewards[tokenAddress],
      token.decimals
    );

    return {
      token,
      amount,
      value: toFiat(amount, token.address)
    };
  });
});

const totalRewardValue = computed((): string => {
  return rewardsData.value
    .reduce((acc, reward) => acc.plus(reward.value), bnum('0'))
    .toString();
});

return (_ctx: any,_cache: any) => {
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalTable = _resolveComponent("BalTable")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    shadow: "lg",
    square: _unref(upToLargeBreakpoint),
    noBorder: _unref(upToLargeBreakpoint),
    noPad: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BalTable, {
        columns: columns.value,
        data: _unref(rewardsData),
        isLoading: __props.isLoading,
        "skeleton-class": "h-64",
        square: _unref(upToLargeBreakpoint)
      }, {
        tokenColumnCell: _withCtx(({ token }) => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_BalAsset, {
              iconURI: token?.logoURI
            }, null, 8, ["iconURI"]),
            _createElementVNode("span", _hoisted_2, _toDisplayString(token.name), 1)
          ])
        ]),
        totalValueCell: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(fNum2)(_unref(totalRewardValue), _unref(FNumFormats).fiat)), 1)
        ]),
        totalClaimCell: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(ClaimRewardsBtn, {
              gauge: __props.gauge,
              fiatValue: _unref(totalRewardValue)
            }, null, 8, ["gauge", "fiatValue"])
          ])
        ]),
        _: 1
      }, 8, ["columns", "data", "isLoading", "square"])
    ]),
    _: 1
  }, 8, ["square", "noBorder"]))
}
}

})