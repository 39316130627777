import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "15",
  viewBox: "0 0 24 15",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["stroke"]
const _hoisted_3 = ["stroke"]

import useDarkMode from '@/composables/useDarkMode';
import useTailwind from '@/composables/useTailwind';
import { computed } from 'vue';


export default _defineComponent({
  setup(__props) {

const { theme } = useTailwind();
const { darkMode } = useDarkMode();

const color = computed(() =>
  darkMode.value ? theme.colors.white : theme.colors.black
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("circle", {
      cx: "7.5",
      cy: "7.5",
      r: "7",
      stroke: _unref(color)
    }, null, 8, _hoisted_2),
    _createElementVNode("circle", {
      cx: "16.5",
      cy: "7.5",
      r: "7",
      stroke: _unref(color)
    }, null, 8, _hoisted_3)
  ]))
}
}

})