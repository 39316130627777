import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Teleport as _Teleport, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6a3955c1")
const _hoisted_1 = { class: "label" }
const _hoisted_2 = { class: "value" }
_popScopeId()

import { computed, ref } from 'vue';
import { format } from 'date-fns';
import { useI18n } from 'vue-i18n';

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import useVeBal from '@/composables/useVeBAL';

import { bnum } from '@/lib/utils';

import { FullPool } from '@/services/balancer/subgraph/types';

import { TokenInfo } from '@/types/TokenList';
import { VeBalLockInfo } from '@/services/balancer/contracts/contracts/veBAL';

import UnlockPreviewModal from '@/components/forms/lock_actions/UnlockForm/components/UnlockPreviewModal/UnlockPreviewModal.vue';
import { PRETTY_DATE_FORMAT } from '@/components/forms/lock_actions/constants';
import useWeb3 from '@/services/web3/useWeb3';

/**
 * TYPES
 */
type Props = {
  lockablePool: FullPool;
  lockablePoolTokenInfo: TokenInfo;
  veBalLockInfo?: VeBalLockInfo;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    lockablePool: { type: null, required: true },
    lockablePoolTokenInfo: { type: null, required: true },
    veBalLockInfo: { type: null, required: false }
  } as unknown as undefined,
  setup(__props: {
  lockablePool: FullPool;
  lockablePoolTokenInfo: TokenInfo;
  veBalLockInfo?: VeBalLockInfo;
}) {

const props = __props


/**
 * STATE
 */
const showUnlockPreviewModal = ref(false);

/**
 * COMPOSABLES
 */
const { balanceFor } = useTokens();
const { fNum2 } = useNumbers();
const { veBalBalance, lockablePoolId } = useVeBal();
const { t } = useI18n();
const { isWalletReady } = useWeb3();

/**
 * COMPUTED
 */
const poolShares = computed(() =>
  bnum(props.lockablePool.totalLiquidity).div(props.lockablePool.totalShares)
);

const bptBalance = computed(() => balanceFor(props.lockablePool.address));

const fiatTotal = computed(() =>
  poolShares.value.times(bptBalance.value).toString()
);

const lockedFiatTotal = computed(() =>
  props.veBalLockInfo?.hasExistingLock
    ? poolShares.value.times(props.veBalLockInfo.lockedAmount).toString()
    : '0'
);

const lockedUntil = computed(() => {
  if (props.veBalLockInfo?.hasExistingLock && !props.veBalLockInfo?.isExpired) {
    return format(props.veBalLockInfo.lockedEndDate, PRETTY_DATE_FORMAT);
  }

  return '—';
});

const totalExpiredLpTokens = computed(() =>
  props.veBalLockInfo?.isExpired ? props.veBalLockInfo.lockedAmount : '0'
);

const fiatTotalExpiredLpTokens = computed(() =>
  bnum(props.lockablePool.totalLiquidity)
    .div(props.lockablePool.totalShares)
    .times(totalExpiredLpTokens.value)
);

const cards = computed(() => {
  const hasExistingLock = props.veBalLockInfo?.hasExistingLock;
  const isExpired = props.veBalLockInfo?.isExpired;

  return [
    {
      id: 'myLpToken',
      label: t('veBAL.myVeBAL.cards.myLpToken', [
        props.lockablePoolTokenInfo?.symbol
      ]),
      value: isWalletReady.value
        ? fNum2(fiatTotal.value, FNumFormats.fiat)
        : '—',
      showPlusIcon: isWalletReady.value ? true : false,
      plusIconTo: {
        name: 'invest',
        params: { id: lockablePoolId.value },
        query: { returnRoute: 'vebal' }
      }
    },
    {
      id: 'myLockedLpToken',
      label: t('veBAL.myVeBAL.cards.myLockedLpToken', [
        props.lockablePoolTokenInfo?.symbol
      ]),
      value: hasExistingLock
        ? fNum2(lockedFiatTotal.value, FNumFormats.fiat)
        : '—',
      showPlusIcon: isWalletReady.value ? true : false,
      plusIconTo: { name: 'get-vebal', query: { returnRoute: 'vebal' } },
      showUnlockIcon: isExpired ? true : false
    },
    {
      id: 'lockedEndDate',
      label: t('veBAL.myVeBAL.cards.lockedEndDate'),
      value: lockedUntil.value,
      showPlusIcon: hasExistingLock ? true : false,
      plusIconTo: { name: 'get-vebal', query: { returnRoute: 'vebal' } }
    },
    {
      id: 'myVeBAL',
      label: t('veBAL.myVeBAL.cards.myVeBAL'),
      showPlusIcon: false,
      value: hasExistingLock
        ? fNum2(veBalBalance.value, FNumFormats.token)
        : '—'
    }
  ];
});

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(cards), (card) => {
      return (_openBlock(), _createBlock(_component_BalCard, {
        key: card.id
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(card.label), 1),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", null, _toDisplayString(card.value), 1),
            _createElementVNode("span", null, [
              (card.showUnlockIcon)
                ? (_openBlock(), _createBlock(_component_BalIcon, {
                    key: 0,
                    name: "minus-circle",
                    class: "pr-2 cursor-pointer",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (showUnlockPreviewModal.value = true))
                  }))
                : _createCommentVNode("", true),
              (card.showPlusIcon)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 1,
                    to: card.plusIconTo,
                    class: "text-blue-500"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BalIcon, {
                        name: "plus-circle",
                        class: "cursor-pointer"
                      })
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 2
      }, 1024))
    }), 128)),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      (showUnlockPreviewModal.value)
        ? (_openBlock(), _createBlock(UnlockPreviewModal, {
            key: 0,
            lockablePool: __props.lockablePool,
            lockablePoolTokenInfo: __props.lockablePoolTokenInfo,
            veBalLockInfo: __props.veBalLockInfo,
            totalLpTokens: _unref(totalExpiredLpTokens),
            fiatTotalLpTokens: _unref(fiatTotalExpiredLpTokens),
            onClose: _cache[1] || (_cache[1] = ($event: any) => (showUnlockPreviewModal.value = false))
          }, null, 8, ["lockablePool", "lockablePoolTokenInfo", "veBalLockInfo", "totalLpTokens", "fiatTotalLpTokens"]))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}
}

})