import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-4 w-full border-b dark:border-gray-900" }
const _hoisted_2 = { class: "-mt-2 p-10 flex items-center justify-center" }
const _hoisted_3 = { class: "text-2xl font-semibold" }
const _hoisted_4 = { class: "flex justify-center border-t dark:border-gray-900" }
const _hoisted_5 = { class: "border-r dark:border-gray-900 p-2 text-center w-1/2" }
const _hoisted_6 = { class: "text-gray-400" }
const _hoisted_7 = { class: "p-3 text-center w-1/2" }
const _hoisted_8 = { class: "text-gray-400" }

import { computed } from 'vue';
import { format } from 'date-fns';

import { bnum } from '@/lib/utils';

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useVeBal from '@/composables/useVeBAL';

import { VeBalLockInfo } from '@/services/balancer/contracts/contracts/veBAL';

import { PRETTY_DATE_FORMAT } from '@/components/forms/lock_actions/constants';

/**
 * TYPES
 */
type Props = {
  veBalLockInfo?: VeBalLockInfo;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    veBalLockInfo: { type: null, required: false }
  } as unknown as undefined,
  setup(__props: {
  veBalLockInfo?: VeBalLockInfo;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { veBalBalance, veBalTokenInfo } = useVeBal();
const { fNum2 } = useNumbers();

/**
 * COMPUTED
 */
const percentVeBAL = computed(() => {
  if (props.veBalLockInfo != null) {
    const totalSupply = bnum(props.veBalLockInfo.totalSupply);

    if (totalSupply.gt(0)) {
      return bnum(veBalBalance.value)
        .div(totalSupply)
        .toString();
    }
  }

  return '0';
});

return (_ctx: any,_cache: any) => {
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    noPad: "",
    shadow: "none"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.$t('getVeBAL.myVeBAL.title')), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(fNum2)(_unref(veBalBalance), _unref(FNumFormats).token)) + " " + _toDisplayString(_unref(veBalTokenInfo)?.symbol), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", null, _toDisplayString(__props.veBalLockInfo?.hasExistingLock
              ? _unref(fNum2)(_unref(percentVeBAL), {
                  style: 'percent',
                  maximumFractionDigits: 4
                })
              : '-'), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('getVeBAL.myVeBAL.percentVeBAL')), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", null, _toDisplayString(props.veBalLockInfo?.hasExistingLock
              ? _unref(format)(props.veBalLockInfo.lockedEndDate, _unref(PRETTY_DATE_FORMAT))
              : '-'), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(props.veBalLockInfo?.isExpired
              ? _ctx.$t('getVeBAL.myVeBAL.expiredOn')
              : _ctx.$t('getVeBAL.myVeBAL.lockedEndDate')), 1)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})